import React, { useState, useEffect } from "react";

const GreaterLessPicker = ({
  onUpdate,
}) => {

  const [selected_logic, set_logic] = useState(null);
  const [selected_value, set_value] = useState(null);


  const change_logic = (value) =>{
     if(value == ''){
       set_logic(null)
       set_value(null)
     }else{
        set_logic(value)
     }

  }

  const update_value = (value) =>{
    if(value == null){
        set_value(null)
    }
    else{
        set_value(Number(value))
    }

  }

   useEffect(() => {
    update_selections()
  }, [selected_logic, selected_value]);

  const update_selections = () =>{
      onUpdate(selected_logic, selected_value)
  }

  let vinput = null;
  if(selected_logic != null){
    vinput = <input onChange={(e) => update_value(e.target.value)}/>
  }

  return(
    <div className="field">
    <div className="control">
      <div className={"select"}>
        <select onChange={(e) => change_logic(e.target.value)}>
            <option value={''}>{'Any'}</option>
            <option value={'>'}>{'>'}</option>
            <option value={'>='}>{'>='}</option>
            <option value={'<'}>{'<'}</option>
            <option value={'<='}>{'<='}</option>
        </select>

        {vinput}

      </div>
    </div>
  </div>
  );
}

export default GreaterLessPicker;
