import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import LogIn from './containers/log_in'
import Home from './containers/home'
import About from './containers/about'
import Bookings from './containers/bookings'
import BookingView from './containers/booking_view'
import Enquiries from './containers/enquiries'
import EnquiryView from './containers/enquiry_view'
import Basket from './containers/basket'
import BaskeView from './containers/basket_view'
import Component_Test from './containers/component_test'
import AuditHome from './containers/audit'
import AccPrices from './containers/audit/acc_prices'
import OfferPrices from './containers/audit/offer_prices'
import FlightPrices from './containers/audit/flight_prices'

import Nav from './components/nav'

import {user_log_in} from './service/sub_user'
import {cookie, getCookie} from './utils/shared'

class App extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user_logged_in: false,
        user_token: '',
        log_in_attempts: 0
      };
    }

    componentDidMount(){
      let uli_tk = getCookie('usign', false);
      if(uli_tk != null){
        this.setState({user_logged_in: true, user_token: uli_tk});
      }
    }


    log_in = async(user_name, password) =>{
      const {log_in_attempts} = this.state;
      user_log_in(user_name, password).then((r) => {
         let la = 0;
         if(!r[0]){
            la = log_in_attempts + 1;
         }
         else{
            cookie('usign', r[3], false, 365);
       
         }
         this.setState({user_logged_in: r[0], log_in_attempts: la, user_token: r[3]});
      });
    }

    log_out = () => {
      cookie('usign', null, false, -100);
      this.setState({user_logged_in: false, log_in_attempts: 0, user_token: null});
    }



    render() {
      const {user_logged_in, log_in_attempts} = this.state

      let log_in_message = null;
      if(log_in_attempts > 0){
        log_in_message = <div>Username / Password incorrect</div>
      }

      if(!user_logged_in){
        return(
          <div className='app' style={{width:'100%'}}>
                <LogIn {...this.props} log_in_func={(u, p) => this.log_in(u, p)} ></LogIn>
                {log_in_message}
          </div>
        )
      }

      return(
        <div className='app' style={{width:'100%'}}>

    

            <BrowserRouter>
            <Nav user_token={this.state.user_token} log_out_func={() => this.log_out()}></Nav>

              <Switch>
                  <Route path="/" render={(props) => (<Home {...props} user_token={this.state.user_token}></Home>)} exact />
                  <Route path="/about" render={(props) => (<About {...props} user_token={this.state.user_token}></About>)} exact />
                  <Route path="/bookings" render={(props) => (<Bookings {...props} user_token={this.state.user_token}></Bookings>)} exact />
                  <Route path="/bookings/view" render={(props) => (<BookingView {...props} user_token={this.state.user_token}></BookingView>)} exact />
                  <Route path="/enquiries" render={(props) => (<Enquiries {...props} user_token={this.state.user_token}></Enquiries>)} exact />
                  <Route path="/enquiries/view" render={(props) => (<EnquiryView {...props} user_token={this.state.user_token}></EnquiryView>)} exact />
                  <Route path="/baskets" render={(props) => (<Basket {...props} user_token={this.state.user_token}></Basket>)} exact />
                  <Route path="/basket/view" render={(props) => (<BaskeView {...props} user_token={this.state.user_token}></BaskeView>)} exact />
                  <Route path="/components/manage" render={(props) => (<Component_Test {...props} user_token={this.state.user_token}></Component_Test>)} exact />
                  <Route path="/audit" render={(props) => (<AuditHome {...props} user_token={this.state.user_token}></AuditHome>)} exact />
                  <Route path="/audit/acc_prices" render={(props) => (<AccPrices {...props} user_token={this.state.user_token}></AccPrices>)} exact />
                  <Route path="/audit/flight_prices" render={(props) => (<FlightPrices {...props} user_token={this.state.user_token}></FlightPrices>)} exact />
                  <Route path="/audit/offer_prices" render={(props) => (<OfferPrices {...props} user_token={this.state.user_token}></OfferPrices>)} exact />

              </Switch>
            </BrowserRouter>
        </div>
      )
    }
}

export default App