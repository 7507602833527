
export const bb_name_from_code = (bb_code) => {

    if(bb_code == 5){
        return 'All inclusive';
    }
    else if(bb_code == 4){
        return 'Full board';
    }
    else if(bb_code == 3){
        return 'Half board';
    }
    else if(bb_code == 2){
        return 'Bed & Breakfast';
    }
    else if(bb_code == 1){
        return 'Room only';
    }
    else{
        return 'n/a';
    }
    
};