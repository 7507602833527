import {api} from '../utils/shared'

export const user_log_in = async(user_name, password) => {
    return new Promise(async (resolve, reject) => {
        await api(
            "/sub_user/login",
            {"user_name": user_name, "password": password},
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const sub_user_client = async(token) => {
	
    return new Promise(async (resolve, reject) => {
        await api(
            "/sub_user/"+ token,
            null,
            "GET",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};