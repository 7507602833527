import React, {useEffect, useState} from 'react';
import {acc_price_range} from '../../../service/audit'
import YMDPicker from '../../../components/ymd_picker'
import GreaterLessPicker from '../../../components/greater_less_picker'
import '../../bookings/style.css';
import moment from 'moment';

const AccPrices = ({user_token}) => {

    const [d_view, setDataView] = useState(null);
	const [d_view_count, setDataViewCount] = useState(0)
	const [selected_page, setSelectedPage] = useState(1)

	// const [arrival_airport, setArrivalAirport] = useState(null)
	// const [departure_point, setDeparturePoint] = useState(null)
	// const [airline, setAirline] = useState(null)

	const [selected_board, setBoard] = useState(-1)
	const [selected_from_date, setSelectedFromDate] = useState(null)
	const [selected_to_date, setSelectedToDate] = useState(null)

	const [selected_chk_from_date, setSelectedFromChkDate] = useState(null)
	const [selected_chk_to_date, setSelectedToChkDate] = useState(null)

	const [selected_logic, setLogic] = useState(null)
	const [selected_logic_value, setLogicValue] = useState(null)

    useEffect(() =>{

		acc_price_range(0, 100, user_token, {}).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
		});

    }, [])
	
	const page = (start) => {
		//change the view
		let selected_page = start + 1;
		let start_row = 0;
		let end_row = 100;
		if(start > 0){
			start_row = start * 100;
			end_row = start_row + 100;
		}
	
		// bookings(start_row, end_row, user_token, {}).then((r) => {
		// 	setDataView(r[0])
		// 	setDataViewCount(r[1])
		// 	setSelectedPage(selected_page)
		// })

		filter(start_row, end_row, selected_page)

	}

	const filter = (start, end, selected_page = 1) => {
		let filters = []

		// filters.push({'field':'arrival_airport', 'type':1, 'value': arrival_airport});
		// filters.push({'field':'departure_point', 'type':1, 'value': departure_point});
		// filters.push({'field':'airline', 'type':1, 'value': airline});
		if (selected_board != -1)
		{
			filters.push({'field':'board', 'type':0, 'value': Number(selected_board)});
		}

		if(selected_logic != null && selected_logic_value != null){
			filters.push({'field':'difference', 'type':3, 'data_type': selected_logic, 'value': selected_logic_value});
		}

		if(selected_from_date != null){

			let from_date = selected_from_date + ' 00:00:00';
			let to_date = selected_to_date;
			if(to_date == null){
				let today_m = moment();
				to_date = today_m.format('YYYY-MM-DD')
			}
			to_date = to_date + ' 23:59:59';

			filters.push({'field':'date_captured', 'type':1, 'value': from_date + ',' + to_date});
		}

		if(selected_chk_from_date != null){
			let dep_from_date = selected_chk_from_date + ' 00:00:00';
			let dep_to_date = selected_chk_to_date;
			if(dep_to_date == null){
				let dep_today_m = moment();
				dep_to_date = dep_today_m.format('YYYY-MM-DD')
			}
			dep_to_date = dep_to_date + ' 23:59:59';
			filters.push({'field':'check_in', 'type':1, 'value': dep_from_date + ',' + dep_to_date});
		}

		acc_price_range(start, end, user_token, filters).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
			setSelectedPage(selected_page)
		})
	}

	const reset_filters = () =>{

		setSelectedFromDate(null)
		setSelectedToDate(null)

		setSelectedFromChkDate(null)
		setSelectedToChkDate(null)

		setLogic(null)
		setLogicValue(null)

		setBoard(-1)

		acc_price_range(0, 100, user_token, {}).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
			setSelectedPage(1)
		});


	}

	const change_from_date = (v) => {
		setSelectedFromDate(v)
	}	
	
	const change_to_date = (v) => {
		setSelectedToDate(v)
	}

	const change_chk_from_date = (v) => {
		setSelectedFromChkDate(v)
	}	
	
	const change_chk_to_date = (v) => {
		setSelectedToChkDate(v)
	}

	const change_board = (v) => {
		setBoard(v)
	}

	const change_logic_f = (logic, value) => {
		setLogic(logic)
		setLogicValue(value)
	}

	let tbl_view = null;
	if(d_view != null){

		let rows = []
		for(let x of d_view){

			let str_date_captured = x.date_captured;
			let str_check_in = x.check_in;

			let board = x.board;

			rows.push(<tr className={''}>
				<td>
					{str_date_captured}
				</td>
				<td>
					{x.adults}
				</td>
				<td>
					{x.children} - {x.infants}
				</td>
				<td>
					{x.duration}
				</td>
				<td>
					{str_check_in}
				</td>
				<td>
					{board}
				</td>
				<td>
					{x.accommodation}
				</td>
				<td>
					{x.original_price}
				</td>
				<td>
					{x.difference}
				</td>
				<td>
					{x.live_price}
				</td>
				<td>
					{x.provider}
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						Date Captured
					</td>
					<td>
						Adults
					</td>
					<td>
						Children - Infants
					</td>
					<td>
						Duration
					</td>
					<td>
						Check in
					</td>
					<td>
						Board
					</td>
					<td>
						Accommodation
					</td>
					<td>
						Cache Price
					</td>
					<td>
						Difference
					</td>
					<td>
						Live Price
					</td>
					<td>
						Provider
					</td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}

	let pagination = null;
	if(d_view_count > 0){
		let number_of_pages = Math.ceil(d_view_count / 100);

		let pagination_items = []
		for(let i=0; i < number_of_pages; i++){
			let page_no = i + 1;
			let key = 'page_' + page_no;
			let page_class = ''
			if(selected_page == page_no){
				page_class = 'selected'
			}
			pagination_items.push(<li key={key} className={page_class} onClick={() => page(i)}>{page_no}</li>)
		}

		pagination = <ul>
			{pagination_items}
		</ul>
	}

	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>
				{/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}
				<div className='filer_sec'>

					<div className='filter_field'>
						<div>
							Board
						</div>
						<div>
							<select onChange={(e) => change_board(e.target.value)}>
								<option value="-1">Any</option>
								<option value="0">Room only</option>
								<option value="1">Self catering</option>
								<option value="2">Bed and breakfast</option>
								<option value="3">Half board</option>
								<option value="4">Full board</option>
								<option value="5">All inclusive</option>
							</select>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Difference
						</div>
						<div>
							<GreaterLessPicker onUpdate={(logic, value) => change_logic_f(logic, value)}></GreaterLessPicker>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Date captured
						</div>
						<div>
							From <YMDPicker id='sel_dc_from' key='sel_bk_from_k' initalValue={selected_from_date} onChange={(value) => change_from_date(value)}></YMDPicker>
							To <YMDPicker id='sel_dc_to' key='sel_bk_to_k' initalValue={selected_to_date} onChange={(value) => change_to_date(value)}></YMDPicker>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Check in date
						</div>
						<div>
							From <YMDPicker id='sel_chk_from' key='sel_dep_from_k' initalValue={selected_chk_from_date} onChange={(value) => change_chk_from_date(value)}></YMDPicker>
							To <YMDPicker id='sel_chk_to' key='sel_dep_to_k' initalValue={selected_chk_to_date} onChange={(value) => change_chk_to_date(value)}></YMDPicker>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							<button onClick={() => filter(0, 100)}>Filter</button>
							<button onClick={() => reset_filters()}>Reset</button>
						</div>
					</div>

				</div>

				<div className='booking_tbl'>
					{tbl_view}
				</div>

				<div className='pagination'>
					{pagination}
				</div>

			</div>
		</section>
	);
};

export default AccPrices;
