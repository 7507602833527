import React, {useState} from 'react';

const AuditHome = (props) => {

	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);

	const acc_prices = () => {
		props.history.push('/audit/acc_prices')
	}

	const flight_prices = () => {
		props.history.push('/audit/flight_prices')
	}

	const offer_prices = () => {
		props.history.push('/audit/offer_prices')
	}
	const changeHandler = (event) => {
				setSelectedFile(event.target.files[0]);
				setIsFilePicked(true);
	};

	return (
	

		<section className='aboutcontent-box'>

   		{/* <div>
			<input type="file" name="file" onChange={changeHandler} />
			<div>
				<button onClick={handleSubmission}>Submit</button>
		</div>
		</div> */}

			<div className='container'>
				{/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}
				<div className='port_sec'>
                  
				<div onClick={() => acc_prices()} className='port'>
				 	<h1>Accommodation Price Changes</h1>
		        </div>

				<div onClick={() => flight_prices()} className='port'>
					<h1>Flight Price Changes</h1>
		        </div>

				<div onClick={() => offer_prices()} className='port'>
					<h1>Offer Price Changes</h1>
		        </div>


				</div>
			</div>
		</section>
	);
};

export default AuditHome;
