export const mode = 'DEV'; // 'PROD';
export const loc = 'https://engine.holidaywebtech.co.uk';
//export const loc = 'https://engine.dev.holidaywebtech.co.uk';
//export const loc = 'http://127.0.0.1:5000';
export const imgloc = '/images/acc/';

export const toFixed = (num, fixed) => {
	num = Number(num);
	return num.toFixed(fixed);
};

export const querySt = (window, key) => {
	var url = window.location.href;
	var KeysValues = window.location.href
		.slice(window.location.href.indexOf('?') + 1)
		.split('&');
	for (var i = 0; i < KeysValues.length; i++) {
		var KeyValue = KeysValues[i];
		var io = KeyValue.indexOf('=') + 1;
		var kname = KeyValue.split('=')[0];
		//KeyValue = KeysValues[i].split("=");
		if (kname == key) {
			var value = KeyValue.substring(io);
			return value;
		}
	}
	return '';
};

export const api = (url, data, method = 'GET', callback, errcallback) => {
	var config = {
		headers: {
			'Content-Type': 'application/json',
		},
		method,
		body: method.toLowerCase() == 'post' ? JSON.stringify(data) : null,
	};

	fetch(loc + url, config)
		.then((res) => {
			if (!res.ok) {
				//Try Log error in DB
				try {
					console.log(res.statusText);
					//DB error log call here
					//res.json().then(err => this.LogError(err));
				} catch (error) {
					//Failed to log into DB console log it and maybe do something else
					console.log(res.statusText);
				}
				errcallback();
			} else {
				callback(res);
			}
		})
		.catch((err) => (errcallback != null ? errcallback(err) : null));
};

export const cookie =(name,value,isJson,exdays) =>{

	if(isJson)
	{
		value = JSON.stringify(value);
	}

	var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
	var expires = "expires="+ d.toUTCString();
	document.cookie = name + "=" + value + ";" + expires + "; path=/";
}

export const getCookie = (cname,isJson) => {
	var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {

			try {
				if(isJson)
				{
					return JSON.parse(c.substring(name.length, c.length));
				}
				else{
					return c.substring(name.length, c.length);
				}
			}
			catch(err){
				console.log(err);
			}

        }
    }
    return null;
}
