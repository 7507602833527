import React, {useEffect, useState} from 'react';
import {api, cookie, getCookie } from '../../utils/shared'
import {enquiries} from '../../service/enquiry'
import YMDPicker from '../../components/ymd_picker'
import '../enquiries/style.css';
import moment from 'moment';

const Enquiries = ({user_token}) => {

    const [d_view, setDataView] = useState(null);
	const [d_view_count, setDataViewCount] = useState(0)
	const [selected_page, setSelectedPage] = useState(1)

	const [enquiry_ref, setEnqRef] = useState(null)

	const [customer_name, setCustomerName] = useState(null)
 
	const [selected_from_date, setSelectedFromDate] = useState(null)
	const [selected_to_date, setSelectedToDate] = useState(null)

    useEffect(() =>{

		enquiries(0, 10, user_token, {}).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
		});

    }, [])
	
	const page = (start) => {
		//change the view
		let selected_page = start + 1;
		let start_row = 0;
		let end_row = 10;
		if(start > 0){
			start_row = start * 10;
			end_row = start_row + 10;
		}
	
		filter(start_row, end_row, selected_page)

	}

	const filter = (start, end, selected_page = 1) => {
		let filters = []
		if(enquiry_ref != null && enquiry_ref != ''){
			filters.push({'field':'id', 'type':0, 'value': Number(enquiry_ref)});
		}
		if(customer_name != null){
			filters.push({'field':'customer_name', 'type':3, 'value': '%' + customer_name + '%', 'data_type': 'text'});
		}
		if(selected_from_date != null){

			let from_date = selected_from_date + ' 00:00:00';
			let to_date = selected_to_date;
			if(to_date == null){
				let today_m = moment();
				to_date = today_m.format('YYYY-MM-DD')
			}
			to_date = to_date + ' 23:59:59';

			filters.push({'field':'"created"', 'type':1, 'value': from_date + ',' + to_date});
		}

		enquiries(start, end, user_token, filters).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
			setSelectedPage(selected_page)
		})
	}

	const reset_filters = () =>{

		setEnqRef(null)
		setSelectedFromDate(null)
		setSelectedToDate(null)
		setCustomerName(null)

		enquiries(0, 10, user_token, {}).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
			setSelectedPage(1)
		});


	}

	const change_from_date = (v) => {
		setSelectedFromDate(v)
	}	
	
	const change_to_date = (v) => {
		setSelectedToDate(v)
	}


	let tbl_view = null;
	if(d_view != null){

		let rows = []
		for(let x of d_view){

			let date_of_enq = moment(x.Created, 'YYYY-MM-DD HHmm');
			let str_enq_date = date_of_enq.format('DD MMMM YYYY');

            let str_departure_date = 'N/A'
            if(x.DepartureDate != null && x.DepartureDate != ''){
                let departure_date = moment(x.DepartureDate, 'YYYY-MM-DD HHmm');
                str_departure_date = departure_date.format('DD MMMM YYYY');
            }
	

			let status = x.Status;

			let status_class = ''
			if(status == 1){
				status_class = 'row_closed'
			}

			rows.push(<tr className={status_class}>
				<td>
					{x.ID}
				</td>
				<td>
					{str_enq_date}
				</td>
				<td>
					{str_departure_date}
				</td>
				<td>
					{x.Customer_Name}
				</td>
				<td>
					{x.Duration}
				</td>
				<td>
					A:{x.Adults} C:{x.Children}
				</td>
				<td>
					<a href={'/enquiries/view?eid=' + x.ID}>View</a>
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						ID
					</td>
					<td>
						Enquiry Date 
					</td>
					<td>
						Departure Date
					</td>
					<td>
						Customer
					</td>
					<td>
						Duration
					</td>
					<td>
						Pax
					</td>
					<td>
						
					</td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}

	let pagination = null;
	if(d_view_count > 0){
		let number_of_pages = Math.ceil(d_view_count / 10);

		let pagination_items = []
		for(let i=0; i < number_of_pages; i++){
			let page_no = i + 1;
			let key = 'page_' + page_no;
			let page_class = ''
			if(selected_page == page_no){
				page_class = 'selected'
			}
			pagination_items.push(<li key={key} className={page_class} onClick={() => page(i)}>{page_no}</li>)
		}

		pagination = <ul>
			{pagination_items}
		</ul>
	}

	let str_enq_ref = ''
	if(enquiry_ref != null){
		str_enq_ref = enquiry_ref;
	}

	let str_customer_name = ''
	if(customer_name != null){
		str_customer_name = customer_name;
	}

	
	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>
				{/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}


				<div className='filer_sec'>
					<div className='filter_field'>
						<div>
							Enquiry reference
						</div>
						<div>
							<input id='txtBookingRef' value={str_enq_ref} onChange={(e) => setEnqRef(e.target.value)}></input>
						</div>
					</div>

					<div className='filter_field'>
						<div>
                            Enquiry date
						</div>
						<div>
							From <YMDPicker id='sel_bk_from' key='sel_bk_from_k' initalValue={selected_from_date} onChange={(value) => change_from_date(value)}></YMDPicker> 
					
							
			 
							To <YMDPicker id='sel_bk_to' key='sel_bk_to_k' initalValue={selected_to_date} onChange={(value) => change_to_date(value)}></YMDPicker>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Name
						</div>
						<div>
							<input id='txtSurname' key='txtSurname' value={str_customer_name} onChange={(e) => setCustomerName(e.target.value)}></input>
						</div>
					</div>			
					
					<div className='filter_field'>
						<div>
							<button onClick={() => filter(0, 10)}>Filter</button>
							<button onClick={() => reset_filters()}>Reset</button>
						</div>
					</div>

				</div>

				<div className='booking_tbl'>
					{tbl_view}
				</div>

				<div className='pagination'>
					{pagination}
				</div>

			</div>
		</section>
	);
};

export default Enquiries;
