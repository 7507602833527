import React, {useEffect, useState} from 'react';
import { offer_price_range, flight_filters, offer_price_range_download } from '../../../service/audit'
import YMDPicker from '../../../components/ymd_picker'
import GreaterLessPicker from '../../../components/greater_less_picker'
import '../../bookings/style.css';
import moment from 'moment';

const OfferPrices = ({user_token}) => {

    const [d_view, setDataView] = useState(null);
	const [d_view_count, setDataViewCount] = useState(0)
	const [selected_page, setSelectedPage] = useState(1)

	const [selected_board, setBoard] = useState(-1)

	const [selected_ob_airline, setObAirline] = useState('')
	const [selected_ib_airline, setIbAirline] = useState('')

	const [selected_depart, setDepart] = useState(-1)
	const [selected_arrival, setArrival] = useState(-1)

	const [selected_from_date, setSelectedFromDate] = useState(null)
	const [selected_to_date, setSelectedToDate] = useState(null)

	const [selected_dep_from_date, setSelectedFromDepDate] = useState(null)
	const [selected_dep_to_date, setSelectedToDepDate] = useState(null)

	const [filters_avail, setAvailableFilters] = useState(null)

	const [selected_logic, setLogic] = useState(null)
	const [selected_logic_value, setLogicValue] = useState(null)

    useEffect(() =>{
		offer_price_range(0, 100, user_token, {}).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
		});

		flight_filters(user_token).then((r) => {
			setAvailableFilters(r)
		});
    }, [])

	const page = (start) => {
		//change the view
		let selected_page = start + 1;
		let start_row = 0;
		let end_row = 100;
		if(start > 0){
			start_row = start * 100;
			end_row = start_row + 100;
		}
	
		// bookings(start_row, end_row, user_token, {}).then((r) => {
		// 	setDataView(r[0])
		// 	setDataViewCount(r[1])
		// 	setSelectedPage(selected_page)
		// })

		filter(start_row, end_row, selected_page)

	}

	const filter = (start, end, selected_page = 1, download=false) => {
		let filters = []

		// filters.push({'field':'arrival_airport', 'type':1, 'value': arrival_airport});
		// filters.push({'field':'departure_point', 'type':1, 'value': departure_point});
		// filters.push({'field':'airline', 'type':1, 'value': airline});
		if (selected_depart != -1)
		{
			filters.push({'field':'departure_airport', 'type':0, 'value': Number(selected_depart)});
		}
		if (selected_arrival != -1)
		{
			filters.push({'field':'arrival_airport', 'type':0, 'value': Number(selected_arrival)});
		}
		if (selected_ob_airline != '')
		{
			filters.push({'field':'ob_airline', 'type':0, 'value': selected_ob_airline});
		}
		if (selected_ib_airline != '')
		{
			filters.push({'field':'ib_airline', 'type':0, 'value': selected_ib_airline});
		}

		if (selected_board != -1)
		{
			filters.push({'field':'board', 'type':0, 'value': Number(selected_board)});
		}

		if(selected_logic != null && selected_logic_value != null){
			filters.push({'field':'aup.difference', 'type':3, 'data_type': selected_logic, 'value': selected_logic_value});
		}


		if(selected_from_date != null){

			let from_date = selected_from_date + ' 00:00:00';
			let to_date = selected_to_date;
			if(to_date == null){
				let today_m = moment();
				to_date = today_m.format('YYYY-MM-DD')
			}
			to_date = to_date + ' 23:59:59';

			filters.push({'field':'aup.date_captured', 'type':1, 'value': from_date + ',' + to_date});
		}

		if(selected_dep_from_date != null){
			let dep_from_date = selected_dep_from_date + ' 00:00:00';
			let dep_to_date = selected_dep_to_date;
			if(dep_to_date == null){
				let dep_today_m = moment();
				dep_to_date = dep_today_m.format('YYYY-MM-DD')
			}
			dep_to_date = dep_to_date + ' 23:59:59';
			filters.push({'field':'aup.departure_date', 'type':1, 'value': dep_from_date + ',' + dep_to_date});
		}
		if(download){
			offer_price_range_download(user_token, filters).then((r) =>{
				//do something else ?
				let xx= r;
			})

		}else{
			offer_price_range(start, end, user_token, filters).then((r) => {
				setDataView(r[0])
				setDataViewCount(r[1])
				setSelectedPage(selected_page)
			})
		}

	}

	const reset_filters = () =>{

		setSelectedFromDate(null)
		setSelectedToDate(null)

		setSelectedFromDepDate(null)
		setSelectedToDepDate(null)

		setLogic(null)
		setLogicValue(null)

		setObAirline('')
		setIbAirline('')

		setDepart(-1)
		setArrival(-1)

		setBoard(-1)

		offer_price_range(0, 100, user_token, {}).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
			setSelectedPage(1)
		});


	}

	const change_from_date = (v) => {
		setSelectedFromDate(v)
	}	
	
	const change_to_date = (v) => {
		setSelectedToDate(v)
	}

	const change_dep_from_date = (v) => {
		setSelectedFromDepDate(v)
	}	
	
	const change_dep_to_date = (v) => {
		setSelectedToDepDate(v)
	}

	const change_board = (v) => {
		setBoard(v)
	}

	const set_depart = (v) => {
		setDepart(v)
	}

	const set_arrival = (v) => {
		setArrival(v)
	}

	const set_ob_airline = (v) => {
		setObAirline(v)
	}

	const set_ib_airline = (v) => {
		setIbAirline(v)
	}

	const change_logic_f = (logic, value) => {
		setLogic(logic)
		setLogicValue(value)
	}

	let tbl_view = null;
	if(d_view != null){

		let rows = []
		for(let x of d_view){

			let str_date_captured = x.date_captured;
			let str_departure_date = x.departure_date;

			let airline = x.airline;

			let departure_code = x.departure_code;
			let departure_airport = x.departure_airport;

			let arrival_code = x.arrival_code;
			let arrival_airport = x.arrival_airport;

			rows.push(<tr className={''}>
				<td>
					{str_date_captured}
				</td>
				<td>
					{x.adults}
				</td>
				<td>
					{x.children} - {x.infants}
				</td>
				<td>
					{x.duration}
				</td>
				<td>
					{x.board}
				</td>
				<td>
					{x.accommodation}
				</td>
				<td>
					{x.acc_diff}
				</td>
				<td>
					{str_departure_date}
				</td>
				<td>
					{departure_airport} ({departure_code})
				</td>
				<td>
					{arrival_airport} ({arrival_code})
				</td>
				<td>
					{airline}
				</td>
				<td>
					{x.flight_diff}
				</td>
				<td>
					{x.original_price}
				</td>
				<td>
					{x.difference}
				</td>
				<td>
					{x.live_price}
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						Date Captured
					</td>
					<td>
						Adults
					</td>
					<td>
						Children - Infants
					</td>
					<td>
						Durartion
					</td>
					<td>
						Board
					</td>
					<td>
						Accommodation
					</td>
					<td>
						Accommodation Difference
					</td>
					<td>
						Departure Date
					</td>
					<td>
						Departure Point
					</td>
					<td>
						Arrival Point
					</td>
					<td>
						Airline
					</td>
					<td>
						Flight Difference
					</td>
					<td>
						Cache Price
					</td>
					<td>
						Difference
					</td>
					<td>
						Live Price
					</td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}

	let pagination = null;
	if(d_view_count > 0){
		let number_of_pages = Math.ceil(d_view_count / 100);

		let pagination_items = []
		for(let i=0; i < number_of_pages; i++){
			let page_no = i + 1;
			let key = 'page_' + page_no;
			let page_class = ''
			if(selected_page == page_no){
				page_class = 'selected'
			}
			pagination_items.push(<li key={key} className={page_class} onClick={() => page(i)}>{page_no}</li>)
		}

		pagination = <ul>
			{pagination_items}
		</ul>
	}


	let option_ob_airline = [];
	let option_ib_airline = [];
	let option_dep_point = [];
	let option_arr_point = [];
	if(filters_avail != null){

		for(let x of filters_avail.ob_airlines){
			let selected = false;
			if(selected_ob_airline == x){
				selected = true
			}
			option_ob_airline.push(<option selected={selected}  value={x}>{x}</option>)
		}

		for(let x of filters_avail.ib_airlines){
			let selected = false;
			if(selected_ib_airline == x){
				selected = true
			}

			option_ib_airline.push(<option selected={selected} value={x}>{x}</option>)
		}

		for(let x of filters_avail.departures){
			let selected = false;
			if(selected_depart == x.id){
				selected = true
			}
			option_dep_point.push(<option selected={selected} value={x.id}>{x.name} ({x.code})</option>)
		}
		for(let x of filters_avail.arrivals){
			let selected = false;
			if(selected_arrival == x.id){
				selected = true
			}
			option_arr_point.push(<option selected={selected} value={x.id}>{x.name} ({x.code})</option>)
		}


	}

	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>
				{/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}
				<div className='filer_sec'>


					<div className='filter_field'>
						<div>
							Board
						</div>
						<div>
							<select onChange={(e) => change_board(e.target.value)}>
								<option value="-1">Any</option>
								<option value="0">Room only</option>
								<option value="1">Self catering</option>
								<option value="2">Bed and breakfast</option>
								<option value="3">Half board</option>
								<option value="4">Full board</option>
								<option value="5">All inclusive</option>
							</select>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Outbound Airline
						</div>
						<div>
							<select onChange={(e) => set_ob_airline(e.target.value)}>
								<option value="">Any</option>
								{option_ob_airline}
							</select>
						</div>
					</div>
					<div className='filter_field'>
						<div>
							Inbound Airline
						</div>
						<div>
							<select onChange={(e) => set_ib_airline(e.target.value)}>
								<option value="">Any</option>
								{option_ib_airline}
							</select>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Departure Point
						</div>
						<div>
							<select onChange={(e) => set_depart(e.target.value)}>
								<option value="-1">Any</option>
								{option_dep_point}
							</select>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Arrival Point
						</div>
						<div>
							<select onChange={(e) => set_arrival(e.target.value)}>
								<option value="-1">Any</option>
								{option_arr_point}
							</select>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Difference
						</div>
						<div>
							<GreaterLessPicker onUpdate={(logic, value) => change_logic_f(logic, value)}></GreaterLessPicker>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Date captured
						</div>
						<div>
							From <YMDPicker id='sel_dc_from' key='sel_bk_from_k' initalValue={selected_from_date} onChange={(value) => change_from_date(value)}></YMDPicker>
							To <YMDPicker id='sel_dc_to' key='sel_bk_to_k' initalValue={selected_to_date} onChange={(value) => change_to_date(value)}></YMDPicker>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Departing
						</div>
						<div>
							From <YMDPicker id='sel_dep_from' key='sel_dep_from_k' initalValue={selected_dep_from_date} onChange={(value) => change_dep_from_date(value)}></YMDPicker>
							To <YMDPicker id='sel_dep_to' key='sel_dep_to_k' initalValue={selected_dep_to_date} onChange={(value) => change_dep_to_date(value)}></YMDPicker>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							<button onClick={() => filter(0, 100)}>Filter</button>
							<button onClick={() => reset_filters()}>Reset</button>

						</div>
					</div>

				</div>

				<div className='booking_tbl'>
					{tbl_view}
				</div>

				<div className='pagination'>
					{pagination}
				</div>

			</div>
		</section>
	);
};

export default OfferPrices;
