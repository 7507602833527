import React, {useEffect, useState} from 'react';
import {api, cookie, getCookie } from '../../utils/shared'
import queryString from 'query-string';
import {enquiry, add_enquiry_note, notes, set_status} from '../../service/enquiry'
import {bb_name_from_code} from '../../service/accommodation'
import '../enquiry_view/style.css';
import moment from 'moment';

const EnquiryView = (props) => {

    const [enquiry_d, setEnquiryData] = useState(null);
	const [enquiry_notes, setEnquiryNotes] = useState(null);
	const [note, setNote] = useState('')
	const [processing, setProcessing] = useState(true)

    useEffect(() =>{
		let queryStrings = queryString.parse(props.history.location.search);
        let enquiry_id = queryStrings.eid;
		enquiry(enquiry_id, props.user_token).then((r) =>{
			setEnquiryData(r)
			get_notes(enquiry_id)
			setProcessing(false);
		});

    }, [])

	const add_note = () => {
		setProcessing(true);
		let queryStrings = queryString.parse(props.history.location.search);
		let note_o = {'note': note }
		let enquiry_id = queryStrings.eid;
		add_enquiry_note(enquiry_id, props.user_token, note_o).then((r) =>{
			get_notes(enquiry_id)
			setProcessing(false);
		})
	}

	const close_enq = () =>{
		setProcessing(true);
		let queryStrings = queryString.parse(props.history.location.search);
		let enquiry_id = queryStrings.eid;
		let note_o = {'note': note }
		set_status(enquiry_id, 1, props.user_token, note_o).then((r) =>{
			setEnquiryData(r)
			get_notes(enquiry_id)
			setProcessing(false);
		
		})
		
	}

	const open_enq = () => {

		setProcessing(true);
		let queryStrings = queryString.parse(props.history.location.search);
		let enquiry_id = queryStrings.eid;
		let note_o = {'note': note }
		set_status(enquiry_id, 0, props.user_token, note_o).then((r) =>{
			setEnquiryData(r)
			get_notes(enquiry_id)
			setProcessing(false);
		})
	}

	const get_notes = (enquiry_id) => {
		notes(enquiry_id, props.user_token).then((nr) => {
			setEnquiryNotes(nr)
		});
	}


	let tbl_view_enq = [];

	let customer_rows = []

	if(enquiry_d != null){

		let customer_counter = 1;
		

		let created_date = moment(enquiry_d.Created, 'YYYY-MM-DD HH:mm');
		created_date = created_date.format('DD MMMM YYYY');

        let str_departure_date = 'N/A'
        if(enquiry_d.DepartureDate != null && enquiry_d.DepartureDate != ''){
            let departure_date = moment(enquiry_d.DepartureDate, 'YYYY-MM-DD HHmm');
            str_departure_date = departure_date.format('DD MMMM YYYY');
        }

		let bb_d = [];
		for(let bb of enquiry_d.Board){

			if(bb_d.length > 0){
				bb_d.push(<br></br>)
			}
			bb_d.push(bb_name_from_code(bb))
		}	
		
		let ratings = [];
		for(let rt of enquiry_d.Ratings){

			if(ratings.length > 0){
				ratings.push(<br></br>)
			}
			ratings.push(rt)
		}

		let Destinations = []
		for(let d of enquiry_d.Destinations){
			if(Destinations.length > 0){
				Destinations.push(<br></br>)
			}
			Destinations.push(d.Name)
		}
		for(let d of enquiry_d.Region){
			if(Destinations.length > 0){
				Destinations.push(<br></br>)
			}
			Destinations.push(d.Name)
		}
		for(let d of enquiry_d.Resorts){
			if(Destinations.length > 0){
				Destinations.push(<br></br>)
			}
			Destinations.push(d.Name)
		}

		let departure_points = []
		for(let dp of enquiry_d.Departure_Points){
			if(departure_points.length > 0){
				departure_points.push(<br></br>)
			}
			departure_points.push(dp.Name + '(' + dp.Code + ')')
		}

		tbl_view_enq = <table>
			<tbody>
				<tr>
					<td>Enquiry reference</td>
					<td>{enquiry_d.ID}</td>
				</tr>
				<tr>
					<td>Date of enquiry</td>
					<td>{created_date}</td>
				</tr>
                <tr>
					<td>Customer</td>
					<td>{enquiry_d.Customer_Name}</td>
				</tr>
                <tr>
					<td>Email address</td>
					<td>{enquiry_d.Email_Address}</td>
				</tr>
                <tr>
					<td>Contact telephone</td>
					<td>{enquiry_d.Telephone}</td>
				</tr>
                <tr>
					<td>Adults</td>
					<td>{enquiry_d.Adults}</td>
				</tr>
                <tr>
					<td>Children</td>
					<td>{enquiry_d.Children}</td>
				</tr>
				<tr>
					<td>Destination(s)</td>
					<td>{Destinations}</td>
				</tr>
				<tr>
					<td>Departure point(s)</td>
					<td>{departure_points}</td>
				</tr>
                <tr>
					<td>Duration</td>
					<td>{enquiry_d.Duration}</td>
				</tr>
                <tr>
					<td>Departure Date</td>
					<td>{str_departure_date}</td>
				</tr>  
				<tr>
					<td>Board(s)</td>
					<td>{bb_d}</td>
				</tr>       
				<tr>
					<td>Rating(s)</td>
					<td>{ratings}</td>
				</tr>      
				<tr>
					<td>Other information</td>
					<td>{enquiry_d.Other_Information}</td>
				</tr>

			</tbody>
		</table>
	}


	let enotes = []
	if(enquiry_notes != null){
		for(let en of enquiry_notes){
			let date_of_note = moment(en.Created, 'YYYY-MM-DD HH:mm');
			date_of_note = date_of_note.format('DD/MM/YY HH:mm');

			let note_r = en.Note.replaceAll('\n', '~')
			let para_txt = note_r.split('~')
			let para_collectionm = []
			for(let p of para_txt){
				para_collectionm.push(<p>{p}</p>)
			}
			enotes.push(
			<div className='note'>
				<div className='note_timestamp'>
					{en.User_Name} @ {date_of_note}
				</div>
				<div className='note_body'>
					{para_collectionm}
				</div>
			</div>)
		}
	}


	let status_msg = []
	let enq_status_btn = [];
	if(enquiry_d != null && !processing){
		enq_status_btn.push(<button id='btn_submit' onClick={() => add_note()}>Add Note</button>);
		if(enquiry_d.Status == 1){

			status_msg.push(<div className='status_msg_con'>This enquiry has been closed</div>)

			enq_status_btn.push(<button id='btn_status_c' onClick={() => open_enq()}>Open enquiry</button>);
		}else{
			enq_status_btn.push(<button id='btn_status_c' onClick={() => close_enq()}>Close enquiry</button>);
		}
	}
	else{
		enq_status_btn.push(<div class="loader"></div>)
	}

	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>

				<div className='booking_tbl tbl_sec'>		
                    {status_msg}
					{tbl_view_enq}
				</div>

				<div className='note_section'>
					{enotes}
				</div>

				<div className='notes_form'>
						<div>
							<textarea onChange={(e) => setNote(e.target.value)} type='t' cols='70' rows='20'></textarea>
						</div>
						<div>
							{enq_status_btn}
						</div>
				</div>
			</div>
		</section>
	);
};

export default EnquiryView;
