import {api} from '../utils/shared'

export const bookings = async(start, end, token, data) => {
    return new Promise(async (resolve, reject) => {
        await api('/booking/range/' + start + '/' + end + '/' + token, data, 'POST', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const booking = async(booking_id, token) => {
    return new Promise(async (resolve, reject) => {
        await api('/booking/view/' + booking_id + '/' + token, null, 'GET', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const notes = async(booking_id, token) => {
    return new Promise(async (resolve, reject) => {
        await api('/booking/notes/' + booking_id + '/' + token, null, 'GET', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const add_booking_note = async(booking_id, token, data) => {
    return new Promise(async (resolve, reject) => {
        await api('/booking/add_note/' + booking_id + '/' + token, data, 'POST', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const set_status = async(booking_id, status, token, data) => {
    return new Promise(async (resolve, reject) => {
        await api('/booking/set_status/' + booking_id + '/' + status + '/' + token, data, 'POST', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};