import {api} from '../utils/shared'

export const component_schema = async(com_id, token) => {
    return new Promise(async (resolve, reject) => {
        await api('/site/component/schema/' + com_id + '/' + token, null, 'GET', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const component_client_config = async(com_id, token) => {
    return new Promise(async (resolve, reject) => {
        await api('/site/component/schema/' + com_id, null, 'GET', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};