import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {booking, add_booking_note, notes, set_status} from '../../service/booking'
import '../booking_view/style.css';
import moment from 'moment';

const BookingView = (props) => {

    const [booking_d, setBookingData] = useState(null);
	const [booking_notes, setBookingNotes] = useState(null);
	const [note, setNote] = useState('')
	const [processing, setProcessing] = useState(true)

    useEffect(() =>{
		let queryStrings = queryString.parse(props.history.location.search);
		let booking_id = queryStrings.bid;
		booking(booking_id, props.user_token).then((r) =>{
			setBookingData(r)
			get_notes(booking_id)
			setProcessing(false);
		});

    }, [])

	const add_note = () => {
		setProcessing(true);
		let queryStrings = queryString.parse(props.history.location.search);
		let note_o = {'note': note }
		let booking_id = queryStrings.bid;
		add_booking_note(booking_id, props.user_token, note_o).then((r) =>{
			get_notes(booking_id)
			setProcessing(false);
		})
	}

	const close_bk = () =>{
		setProcessing(true);
		let queryStrings = queryString.parse(props.history.location.search);
		let booking_id = queryStrings.bid;
		let note_o = {'note': note }
		set_status(booking_id, 1, props.user_token, note_o).then((r) =>{
			setBookingData(r)
			get_notes(booking_id)
			setProcessing(false);
		
		})
		
	}

	const open_bk = () => {

		setProcessing(true);
		let queryStrings = queryString.parse(props.history.location.search);
		let booking_id = queryStrings.bid;
		let note_o = {'note': note }
		set_status(booking_id, 0, props.user_token, note_o).then((r) =>{
			setBookingData(r)
			get_notes(booking_id)
			setProcessing(false);
		})
	}

	const get_notes = (booking_id) => {
		notes(booking_id, props.user_token).then((nr) => {
			setBookingNotes(nr)
		});
	}

	const extra_sec = (e, i) => {

		if(e.QuantityRequired > 0){
			i.push(<tr>
				<td>Code</td>
				<td>{e.Code}</td>
			</tr>)
			i.push(<tr>
				<td>Description</td>
				<td>{e.Description}</td>
			</tr>)
			i.push(<tr>
				<td>Qty required</td>
				<td>{e.QuantityRequired}</td>
			</tr>)
			if(e.Provider_Price != null){
				i.push(<tr>
					<td>Provider cost</td>
					<td>{e.Provider_Price}</td>
				</tr>)
			}
			if(e.Margin != null){
				i.push(<tr>
					<td>Margin</td>
					<td>{e.Margin}</td>
				</tr>)		
			}
			if(e.Original_Price != null){
				i.push(<tr>
					<td>Original cost</td>
					<td>{e.Original_Price}</td>
				</tr>)	
			}
			if(e.Discount != null){
				i.push(<tr>
					<td>Discount</td>
					<td>{e.Discount}</td>
				</tr>)	
			}
			if(e.Price != null){

				i.push(<tr>
					<td>Item cost</td>
					<td>{e.Price}</td>
				</tr>)	

				i.push(<tr>
					<td>Total cost</td>
					<td>{e.Price * e.QuantityRequired}</td>
				</tr>)
			}
		}

		return i;
	}
	

	let tbl_view_booking = [];
	let tbl_view_payment = null;
	let tbl_view_flights =  null;
	let tbl_view_acc = null;
	let tbl_view_transfer = null;
	let tbl_total_cost = null;

	

	let customer_rows = []

	if(booking_d != null){

		let customer_counter = 1;
		for(let p of booking_d.People){

			let dob = moment(p.DateOfBirth, 'YYYY-MM-DD');
			dob = dob.format('DD/MM/YY');

			if(customer_counter == 1){
				//is lead pax
				customer_rows.push(<tr>
					<td className='title_row' colspan='2'>Lead customer</td>
				</tr>)
				customer_rows.push(<tr>
					<td>Name</td>
					<td>{p.Title} {p.FirstName} {p.Surname}</td>
				</tr>)
				customer_rows.push(<tr>
					<td>Date of birth</td>
					<td>{dob}</td>
				</tr>)
				customer_rows.push(<tr>
					<td>Address</td>
					<td>{p.Address1} <br/> {p.Address2} <br/> {p.Address3} <br/> {p.Address4} <br/>  {p.PostCode}</td>
				</tr>)
				customer_rows.push(<tr>
					<td>Contact telelphone</td>
					<td>{p.ContactTelephone}</td>
				</tr>)
				customer_rows.push(<tr>
					<td>Email address</td>
					<td>{p.EmailAddress}</td>
				</tr>)
				customer_rows.push(<tr>
					<td>Special requests</td>
					<td>{p.SpecialRequests}</td>
				</tr>)
			}
			else{
				customer_rows.push(<tr>
					<td className='title_row' colspan='2'>Customer {customer_counter}</td>
				</tr>)
				customer_rows.push(<tr>
					<td>Name</td>
					<td>{p.Title} {p.FirstName} {p.Surname}</td>
				</tr>)
				customer_rows.push(<tr>
					<td>Date of birth</td>
					<td>{dob}</td>
				</tr>)
			}

			customer_counter++;
		}

		let booking_date = moment(booking_d.Booking.DateOfBooking, 'YYYY-MM-DD HH:mm');
		booking_date = booking_date.format('DD MMMM YYYY HH:mm');

		tbl_view_booking = <table>
			<tbody>
				<tr>
					<td>Booking reference</td>
					<td>{booking_d.Booking.ID}</td>
				</tr>
				<tr>
					<td>Date of booking</td>
					<td>{booking_date}</td>
				</tr>
				{customer_rows}
			</tbody>
		</table>

		let payment_method = 'Full amount';
		if(booking_d.Payment.Method != 1){
			payment_method = 'Deposit';
		}
		tbl_view_payment = <table>
			<tbody>
				<tr>
					<td>Card number</td>
					<td>{booking_d.Payment.CardNumber}</td>
				</tr>
				<tr>
					<td>Auth code</td>
					<td>{booking_d.Payment.AuthCode}</td>
				</tr>
				<tr>
					<td>Payment method</td>
					<td>{payment_method}</td>
				</tr>
				<tr>
					<td>Amount taken</td>
					<td>{booking_d.Payment.AmountTaken}</td>
				</tr>
			</tbody>
		</table>

		let selected_outbound = null;
		let selected_inbound = null;
		let selected_room = null;
		let flight_costings = [];
		let flight_inbound_costings = [];
		let flight_extras = [];
		let flight_extras_inbound = [];
		
		for(let obf of booking_d.Holiday.OutboundFlights){
			if(obf.ID == booking_d.Holiday.OutboundFlight){
				selected_outbound = obf;
				break;
			}
		}

		for(let ibf of booking_d.Holiday.ReturnFlights){
			if(ibf.ID == booking_d.Holiday.ReturnFlight){
				selected_inbound = ibf;
				break;
			}
		}

		for(let rm of booking_d.Holiday.Rooms){
			if(rm.ID == booking_d.Holiday.SelectedRoom){
				selected_room = rm;
				break;
			}
		}

		let room_reference = '';
		if(selected_room.Booking_Component != null){
			room_reference = selected_room.Booking_Component.Reference;
		}


		let room_margin = null;
		if(selected_room.Margin != null){
			room_margin = 	<tr>
						<td>Margin</td>
						<td>{selected_room.Margin}</td>
					</tr>;
		}
		let room_provider_price = null;
		if(selected_room.Provider_Price != null){
			room_provider_price = <tr>
						<td>Provider cost</td>
						<td>{selected_room.Provider_Price}</td>
					</tr>
		}
		let room_orig_price = null;
		if(selected_room.Original_Price != null){
			room_orig_price =  <tr>
						<td>Original cost</td>
						<td>{selected_room.Original_Price}</td>
					</tr>
		}	
		let room_discount = null;
		if(selected_room.Discount != null){
			room_discount =  <tr>
						<td>Discount</td>
						<td>{selected_room.Discount}</td>
					</tr>
		}
		let room_cost = null;
		if(selected_room.Price != null){
			room_cost = <tr>
						<td>Cost</td>
						<td>{selected_room.Price}</td>
					</tr>
		}



		tbl_view_acc = <table>
		<tbody>
			<tr>
				<td className='title_row' colspan='2'>Accommodation</td>
			</tr>
			<tr>
						<td>Booking reference</td>
						<td>{room_reference}</td>
			</tr>
			<tr>
				<td>Name</td>
				<td>{booking_d.Holiday.Accommodation.Name}</td>
			</tr>
			<tr>
				<td>Address</td>
				<td>{booking_d.Holiday.Accommodation.Address}</td>
			</tr>
			<tr>
				<td>Room</td>
				<td>{selected_room.Description + ' ' + selected_room.Board_Description}<br/>
					{selected_room.Supplier}
				</td>
			</tr>
			<tr>
				<td>Supplier comments</td>
				<td><br/>
					{selected_room.Comments}
				</td>
			</tr>
			{room_provider_price}
			{room_margin}
			{room_orig_price}
			{room_discount}
			{room_cost}
		</tbody>
		</table>


		if(selected_outbound != null && selected_inbound != null){
			let ispackage = selected_outbound.IsPackage;
			let ob_errata = []
			for(let x of selected_outbound.Errata){
				ob_errata.push(x);
			}

			let ib_errata = []
			for(let x of selected_inbound.Errata){
				ib_errata.push(x);
			}

			let outbound_flight_departing = moment(selected_outbound.Departing, 'YYYY-MM-DD.HH:mm')
			outbound_flight_departing = outbound_flight_departing.format('DD/MM/YY HH:mm');
			let outbound_flight_arrival =  moment(selected_outbound.Arrival, 'YYYY-MM-DD.HH:mm')
			outbound_flight_arrival = outbound_flight_arrival.format('DD/MM/YY HH:mm');

			let inbound_flight_departing = moment(selected_inbound.Departing, 'YYYY-MM-DD.HH:mm')
			inbound_flight_departing = inbound_flight_departing.format('DD/MM/YY HH:mm');
			let inbound_flight_arrival =  moment(selected_inbound.Arrival, 'YYYY-MM-DD.HH:mm')
			inbound_flight_arrival = inbound_flight_arrival.format('DD/MM/YY HH:mm');

			let flight_booking_reference = '';
			let flight_booking_reference_inbound = '';
			if(ispackage){
				if(selected_outbound.Provider_Price != null){
					flight_costings.push(<tr>
						<td>Flight provider cost</td>
						<td>{selected_outbound.Provider_Price}</td>
					</tr>)
				}
				if(selected_outbound.Margin != null){
					flight_costings.push(<tr>
						<td>Flight margin</td>
						<td>{selected_outbound.Margin}</td>
					</tr>)
				}
				if(selected_outbound.Original_Price != null){
					flight_costings.push(<tr>
						<td>Flight original cost</td>
						<td>{selected_outbound.Original_Price}</td>
					</tr>)
				}
				if(selected_outbound.Discount != null){
					flight_costings.push(<tr>
						<td>Flight discount</td>
						<td>{selected_outbound.Discount}</td>
					</tr>)		
				}	
				if(selected_outbound.Price != null){
					flight_costings.push(<tr>
						<td>Flight cost</td>
						<td>{selected_outbound.Price}</td>
					</tr>)
				}

				if(selected_outbound.Booking_Component != null){
					flight_booking_reference = selected_outbound.Booking_Component.Reference;
					flight_booking_reference_inbound = selected_outbound.Booking_Component.Reference;
				}

			}else{
				//outbound costs
				if(selected_outbound.Provider_Price != null){
					flight_costings.push(<tr>
						<td>Provider cost</td>
						<td>{selected_outbound.Provider_Price}</td>
					</tr>)
				}
			
				if(selected_outbound.Margin != null){
					flight_costings.push(<tr>
						<td>Flight margin</td>
						<td>{selected_outbound.Margin}</td>
					</tr>)
				}
				if(selected_outbound.Original_Price != null){
					flight_costings.push(<tr>
						<td>Flight original cost</td>
						<td>{selected_outbound.Original_Price}</td>
					</tr>)
				}
				if(selected_outbound.Discount != null){
					flight_costings.push(<tr>
						<td>Flight discount</td>
						<td>{selected_outbound.Discount}</td>
					</tr>)		
				}	
				if(selected_outbound.Price != null){
					flight_costings.push(<tr>
						<td>Flight cost</td>
						<td>{selected_outbound.Price}</td>
					</tr>)
				}

				if(selected_outbound.Booking_Component != null){
					flight_booking_reference = selected_outbound.Booking_Component.Reference;
				}

				//return costs 
				if(selected_inbound.Provider_Price != null){
					flight_inbound_costings.push(<tr>
						<td>Provider cost</td>
						<td>{selected_inbound.Provider_Price}</td>
					</tr>)
				}
			
				if(selected_inbound.Margin != null){
					flight_inbound_costings.push(<tr>
						<td>Flight margin</td>
						<td>{selected_inbound.Margin}</td>
					</tr>)
				}
				if(selected_inbound.Original_Price != null){
					flight_inbound_costings.push(<tr>
						<td>Flight original cost</td>
						<td>{selected_inbound.Original_Price}</td>
					</tr>)
				}
				if(selected_inbound.Discount != null){
					flight_inbound_costings.push(<tr>
						<td>Flight discount</td>
						<td>{selected_inbound.Discount}</td>
					</tr>)		
				}	
				if(selected_inbound.Price != null){
					flight_inbound_costings.push(<tr>
						<td>Flight cost</td>
						<td>{selected_inbound.Price}</td>
					</tr>)
				}

				if(selected_inbound.Booking_Component != null){
					flight_booking_reference_inbound = selected_inbound.Booking_Component.Reference;
				}
			}

			if(!ispackage){
				let have_inbound_extras = false;
				for(let e of selected_inbound.Extras){
					if(e.QuantityRequired > 0){
						have_inbound_extras = true;
					}
					extra_sec(e, flight_extras_inbound)
				}
				if(have_inbound_extras){
					flight_extras_inbound.splice(0,0,<tr>
						<td className='title_row' colspan='2'>Inbound extras</td>
					</tr>)
				}
			}

			let have_outbound_extras = false;
			for(let e of selected_outbound.Extras){
				if(e.QuantityRequired > 0){
					have_outbound_extras = true;
				}
				extra_sec(e, flight_extras)
			}
			if(have_outbound_extras){
				flight_extras.splice(0,0,<tr>
					<td className='title_row' colspan='2'>Outbound extras</td>
				</tr>)
			}

			tbl_view_flights = <table>
				<tbody>
					<tr>
						<td className='title_row' colspan='2'>Outbound flight</td>
					</tr>
					<tr>
						<td>Booking reference</td>
						<td>{flight_booking_reference}</td>
					</tr>
					<tr>
						<td>Route</td>
						<td>{selected_outbound.DepartureAirport.Code.toUpperCase() + '-' + selected_outbound.ArrivalAirport.Code.toUpperCase()}</td>
					</tr>
					<tr>
						<td>Supplier</td>
						<td>{selected_outbound.Airline}</td>
					</tr>
					<tr>
						<td>Flight number</td>
						<td>{selected_outbound.Number}</td>
					</tr>
					<tr>
						<td>Departing</td>
						<td>{outbound_flight_departing}</td>
					</tr>
					<tr>
						<td>Arriving</td>
						<td>{outbound_flight_arrival}</td>
					</tr>
					<tr>
						<td>Errata</td>
						<td>{ob_errata}</td>
					</tr>
					{flight_costings}
					<tr>
						<td className='title_row' colspan='2'>Return flight</td>
					</tr>
					<tr>
						<td>Booking reference</td>
						<td>{flight_booking_reference_inbound}</td>
					</tr>
					<tr>
						<td>Route</td>
						<td>{selected_inbound.DepartureAirport.Code.toUpperCase() + '-' + selected_inbound.ArrivalAirport.Code.toUpperCase()}</td>
					</tr>
					<tr>
						<td>Supplier</td>
						<td>{selected_inbound.Airline}</td>
					</tr>
					<tr>
						<td>Flight number</td>
						<td>{selected_inbound.Number}</td>
					</tr>
					<tr>
						<td>Departing</td>
						<td>{inbound_flight_departing}</td>
					</tr>
					<tr>
						<td>Arriving</td>
						<td>{inbound_flight_arrival}</td>
					</tr>
					<tr>
						<td>Errata</td>
						<td>{ib_errata}</td>
					</tr>
					{flight_inbound_costings}
					{flight_extras}
					{flight_extras_inbound}
				</tbody>
			</table>
		}

		if(booking_d.Holiday.Transfer != null){
			let selected_transfer;

			for(let t of booking_d.Holiday.Transfers){
				if(t.ID == booking_d.Holiday.Transfer){
					selected_transfer = t;
					break;
				}
			}

			let transfer_booking_ref = '';
			if(selected_transfer.Booking_Component != null){
				transfer_booking_ref = selected_transfer.Booking_Component.Reference;
			}

			let t_margin = null;
			if(selected_transfer.Margin != null){
				t_margin = 	<tr>
							<td>Margin</td>
							<td>{selected_transfer.Margin}</td>
						</tr>;
			}
			let t_provider_price = null;
			if(selected_transfer.Provider_Price != null){
				t_provider_price = <tr>
							<td>Provider cost</td>
							<td>{selected_transfer.Provider_Price}</td>
						</tr>
			}
			let t_orig_price = null;
			if(selected_transfer.Original_Price != null){
				t_orig_price =  <tr>
							<td>Original cost</td>
							<td>{selected_transfer.Original_Price}</td>
						</tr>
			}	
			let t_discount = null;
			if(selected_transfer.Discount != null){
				t_discount =  <tr>
							<td>Discount</td>
							<td>{selected_transfer.Discount}</td>
						</tr>
			}
			let t_cost = null;
			if(selected_transfer.Price != null){
				t_cost = <tr>
							<td>Cost</td>
							<td>{selected_transfer.Price}</td>
						</tr>
			}

			tbl_view_transfer = <table>
			<tbody>
				<tr>
					<td className='title_row' colSpan='2'>Transfer</td>
				</tr>
				<tr>
					<td>Booking reference</td>
					<td>{transfer_booking_ref}</td>
				</tr>
				<tr>
					<td>Type</td>
					<td>{selected_transfer.ProductType}</td>
				</tr>
				<tr>
					<td>Description</td>
					<td>{selected_transfer.Description}</td>
				</tr>
				{t_provider_price}
				{t_margin}
				{t_orig_price}
				{t_discount}
				{t_cost}
			</tbody>
			</table>
		}

		let holiday_margin = null;
		if(booking_d.Holiday.Margin != null){
			holiday_margin = <tr>
							<td>Margin</td>
							<td>{booking_d.Holiday.Margin}</td>
						</tr>;
		}

		let deposit_cost = null;
		if(booking_d.Holiday.Deposit != null){
			let dep_due = moment(booking_d.Holiday.Deposit.Due, 'YYYY-MM-DD');
			dep_due = dep_due.format('DD MMMM YYYY');
			deposit_cost = <tr>
							<td>Deposit</td>
							<td>{booking_d.Holiday.Deposit.Amount} (Due {dep_due})</td>
						</tr>;
		}

		tbl_total_cost = <table>
		<tbody>
				{holiday_margin}
				{deposit_cost}
				<tr>
					<td>Cost</td>
					<td>{booking_d.Holiday.Price}</td>
				</tr>
			</tbody>
		</table>
	}


	let bnotes = []
	if(booking_notes != null){
		for(let bn of booking_notes){

			let date_of_note = moment(bn.Created, 'YYYY-MM-DD HH:mm');
			date_of_note = date_of_note.format('DD/MM/YY HH:mm');

			let note_r = bn.Note.replaceAll('\n', '~')
			let para_txt = note_r.split('~')
			let para_collectionm = []
			for(let p of para_txt){
				para_collectionm.push(<p>{p}</p>)
			}

			bnotes.push(
			<div className='note'>
				<div className='note_timestamp'>
					{bn.User_Name} @ {date_of_note}
				</div>
				<div className='note_body'>
					{para_collectionm}
				</div>
			</div>)
		}
	}


	let status_msg = []
	let book_status_btn = [];
	if(booking_d != null && !processing){
		book_status_btn.push(<button id='btn_submit' onClick={() => add_note()}>Add Note</button>);
		if(booking_d.Booking.Status == 1){

			status_msg.push(<div className='status_msg_con'>This booking has been closed</div>)

			book_status_btn.push(<button id='btn_status_c' onClick={() => open_bk()}>Open booking</button>);
		}else{
			book_status_btn.push(<button id='btn_status_c' onClick={() => close_bk()}>Close booking</button>);
		}
	}
	else{
		book_status_btn.push(<div class="loader"></div>)
	}

	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>

		
		
		

				<div className='booking_tbl tbl_sec'>		{status_msg}
					{tbl_view_booking}
				</div>

				<div className='payment_tbl tbl_sec'>
					{tbl_view_payment}
				</div>

				<div className='flight_tbl tbl_sec'>
					{tbl_view_flights}
				</div>

				<div className='transfer_tbl tbl_sec'>
					{tbl_view_transfer}
				</div>

				<div className='acc_tbl tbl_sec'>
					{tbl_view_acc}
				</div>

				<div className='cost_tbl tbl_sec'>
					{tbl_total_cost}
				</div>

				<div className='note_section'>
					{bnotes}
				</div>

				<div className='notes_form'>
						<div>
							<textarea onChange={(e) => setNote(e.target.value)} type='t' cols='70' rows='20'></textarea>
						</div>
						<div>
								{book_status_btn}

						
						</div>
				</div>
		
		
					
			


				


			</div>
		</section>
	);
};

export default BookingView;
