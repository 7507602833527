import React, {useState} from 'react';

const Home = (props) => {

	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);

	const bookings = () => {
		props.history.push('/bookings')
	}

	const enquiries = () =>{
		props.history.push('/enquiries')
	}	
	
	const baskets = () =>{
		props.history.push('/baskets')
	}

	const components = () =>{
		props.history.push('/components/manage/')
	}

	const audit = () =>{
		props.history.push('/audit')
	}

	const changeHandler = (event) => {
				setSelectedFile(event.target.files[0]);
				setIsFilePicked(true);
	};
		
	// const handleSubmission = () => {
	// 	const formData = new FormData();
	// 	formData.append('file', selectedFile);
	//
	// 	fetch(
	// 		'http://127.0.0.1:5000/client_user/file/geghwfjvelnigalxycwp',
	// 		{
	// 			method: 'POST',
	// 			body: formData,
	// 		}
	// 		)
	// 		.then((response) => response.json())
	// 		.then((result) => {
	// 			console.log('Success:', result);
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error:', error);
	// 		});
	// 		};
	


	

	return (
	

		<section className='aboutcontent-box'>

   		{/* <div>
			<input type="file" name="file" onChange={changeHandler} />
			<div>
				<button onClick={handleSubmission}>Submit</button>
		</div>
		</div> */}

			<div className='container'>
				{/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}
				<div className='port_sec'>
                  
				<div onClick={() => bookings()} className='port'>
				 	<h1>Bookings</h1> 
		        </div>

				<div onClick={() => enquiries()} className='port'>
					<h1>Enquiries</h1> 
		        </div>

				<div onClick={() => baskets()} className='port'>
					<h1>Basket Lookup</h1> 
		        </div>

				<div onClick={() => audit()} className='port'>
					<h1>Audit</h1>
		        </div>

				{/* <div onClick={() => components()} className='port'>
					<h1>Component Manage</h1> 
		        </div>  */}

				

				</div>
			</div>
		</section>
	);
};

export default Home;
