import React, { useContext, useMemo } from "react";

const ServerDataContext = React.createContext();

export const ServerDataProvider = (props) => {
	const value = useMemo(() => {
		return {
			dataCache: props.dataCache,
		};
	}, [props.dataCache]);

	return (
		<ServerDataContext.Provider value={value}>
			{props.children}
		</ServerDataContext.Provider>
	);
};

export const useServerData = (fn) => {
	try {
		const context = useContext(ServerDataContext);

		if (!context) {
			throw new Error(
				"useServerData() must be a child of <ServerDataProvider />"
			);
		}

		if (fn) {
			return fn(context.dataCache);
		} else {
			return context.dataCache;
		}
	} catch (error) {
		console.log(error, "error");
	}
};
