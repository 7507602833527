import React from 'react';

const About = () => {
	return (
	

		<section className='aboutcontent-box'>
	
			<div className='container'>
				{/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}
				<div className='aboutParam'>

				<div>
		</div>

					<p>
						First and foremost, all our flight inclusive holidays carry the gold
						standard seal of financial protection – they are ATOL bonded. This
						means that in the event one of the suppliers in the chain being
						unable to fulfil their part of the package – be it the airline, the
						car hire provider , the transfer provider or the hotel, we will work
						hard to find you an alternative at no extra charge. If there are no
						suitable alternatives (as per the Package Travel Regulations), you
						have the right to a full refund. We will always provide you an ATOL
						certificate upon completion of a flight inclusive holiday.
					</p>

					<p>
						Additionally, we are bonded under an ABTA licence, so in the
						extremely unlikely event of our failure, your money is 100% secure.{' '}
					</p>

					<p>
						How are we able to provide you the cheapest holidays on the market?
					</p>

					<p>
						The answer is simply that our entire focus over the last 10 years
						has been to develop the best holiday search technology in the
						marketplace. The best technology means we have far lower overheads
						than any of our competitors, and can pass this saving on to you, the
						customer.
					</p>

					<p>
						We are so confident we can beat our competitors’ prices, that if you
						do find the same holiday, bookable at the same time, with another
						online travel agent, we will beat their total price by £30. That is
						the HolidayPeople Price Guarantee.
					</p>
				</div>
			</div>
		</section>
	);
};

export default About;
