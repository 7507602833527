import {api} from '../utils/shared'

export const baskets = async(start, end, token, data) => {
    return new Promise(async (resolve, reject) => {
        await api('/basket/range/' + start + '/' + end + '/' + token, data, 'POST', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const basket = async(basket_ref, token) => {
    return new Promise(async (resolve, reject) => {
        await api('/basket/view/' + basket_ref + '/' + token, null, 'GET', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

