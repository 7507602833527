import {api} from '../utils/shared'

export const acc_price_range = async(start, end, token, data) => {
    return new Promise(async (resolve, reject) => {
        await api('/aud/acc/range/' + start + '/' + end + '/' + token, data, 'POST', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const flight_price_range = async(start, end, token, data) => {
    return new Promise(async (resolve, reject) => {
        await api('/aud/flight/range/' + start + '/' + end + '/' + token, data, 'POST', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });

};

export const flight_filters = async(token) => {
    return new Promise(async (resolve, reject) => {
        await api('/aud/flight/available_filters/' + token, null, 'GET', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const offer_price_range = async(start, end, token, data) => {
    return new Promise(async (resolve, reject) => {
        await api('/aud/offer/range/' + start + '/' + end + '/' + token, data, 'POST', (d) =>{
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const offer_price_range_download = async(token, data) => {
    return new Promise(async (resolve, reject) => {
        await api('/aud/offer/dl_view/' + token, data, 'POST', (d) =>{
                return d;
            },
            (e) => console.log(e)
        );
    });
};