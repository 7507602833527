
import React, { useState, useEffect,  } from "react";
import {sub_user_client} from "../service/sub_user";
import { withRouter } from 'react-router';
import styled from 'styled-components'


const Container = styled.div`
  width:100%;
  height: 50px;
  font-family: "Josefin Sans", sans-serif;
  color: white;
  display:flex;
  align-items:center;
  background-color:grey;
`;
const ContainerLeft = styled.div`
    margin-right:auto;
`;

const ContainerLeftLogo = styled.div`
    width:100px;
    height:30px;
`;


const LogoImage = styled.img`
    max-width:100px;
    max-height:30px;
    margin-left:20px; 
`;

const ContainerRight = styled.div`
    margin-left:auto;
`;


const StyleLink = styled.a`

    cursor:pointer;
    &:hover{
        color:blue;
    }
    
`;

const HoizList = styled.ul`
    list-style:none;
    display:flex;
    li{
        border-right: 1px #ccc dashed;
        padding:2px 8px 2px 5px;
    }
    li:last-child{
        border-right: none;
    }
`;

const Nav = ({
    history, 
    log_out_func,
    user_token
  }) => {

    const [sub_user_d, setSubUserD] = useState(null)

    useEffect(() =>{

		sub_user_client(user_token).then((d) => {
            if(d != null){
                setSubUserD(d);
            }
        })

    }, [])
	

    const navigate = (path) => {
        history.push(path)
    }

    const logout = () => {
        log_out_func()
    }


    let links = [{'url': '/', 'name': 'Home'}, {'url': '/bookings', 'name': 'Bookings'}, 
    {'url': '/enquiries', 'name': 'Enquiries'} , {'url': '/baskets', 'name': 'Baskets'}]

    let list_links = [];
    let i = 0;
    for(let l of links){

        let active_style = null;
        if(l.url == window.location.pathname){
            active_style = {color:'blue'};
            list_links.push(<li key={'lstitem' + i}><StyleLink style={active_style} onClick={() => navigate(l.url)}>{l.name}</StyleLink></li>)
        }
        else{
            list_links.push(<li key={'lstitem' + i}><StyleLink onClick={() => navigate(l.url)}>{l.name}</StyleLink></li>)
        }
        i++;
    }

    let logo = null;
    if(sub_user_d != null){
        logo = <LogoImage src={'/images/clogo/' + sub_user_d.client_id +'.png'}></LogoImage>
    }
   
    return(
        <Container>

    <ContainerLeftLogo>{logo}</ContainerLeftLogo>
            <ContainerLeft>
            
                <div>    
        
                    <HoizList>
                        {list_links}
                    </HoizList>
                </div>
           
            </ContainerLeft>

            <ContainerRight>
                <div style={{marginRight: '15px'}}>
                    <StyleLink onClick={() => logout()}>Logout</StyleLink>
                </div>
            </ContainerRight>

        </Container>
    )
  }

  export default withRouter(Nav);

