import React, {useEffect, useState} from 'react';
import {bookings} from '../../service/booking'
import YMDPicker from '../../components/ymd_picker'
import '../bookings/style.css';
import moment from 'moment';

const Bookings = ({user_token}) => {

    const [d_view, setDataView] = useState(null);
	const [d_view_count, setDataViewCount] = useState(0)
	const [selected_page, setSelectedPage] = useState(1)

	const [booking_ref, setBookingRef] = useState(null)

	const [surname, setSurname] = useState(null)
 
	const [selected_from_date, setSelectedFromDate] = useState(null)
	const [selected_to_date, setSelectedToDate] = useState(null)

	const [selected_dep_from_date, setSelectedFromDepDate] = useState(null)
	const [selected_dep_to_date, setSelectedToDepDate] = useState(null)

    useEffect(() =>{

		bookings(0, 100, user_token, {}).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
		});

    }, [])
	
	const page = (start) => {
		//change the view
		let selected_page = start + 1;
		let start_row = 0;
		let end_row = 100;
		if(start > 0){
			start_row = start * 100;
			end_row = start_row + 100;
		}
	
		// bookings(start_row, end_row, user_token, {}).then((r) => {
		// 	setDataView(r[0])
		// 	setDataViewCount(r[1])
		// 	setSelectedPage(selected_page)
		// })

		filter(start_row, end_row, selected_page)

	}

	const filter = (start, end, selected_page = 1) => {
		let filters = []
		if(booking_ref != null && booking_ref != ''){
			filters.push({'field':'b."ID"', 'type':0, 'value': Number(booking_ref)});
		}
		if(surname != null){
			filters.push({'field':'cust."Surname"', 'type':0, 'value': surname, 'data_type': 'text'});
		}
		if(selected_from_date != null){

			let from_date = selected_from_date + ' 00:00:00';
			let to_date = selected_to_date;
			if(to_date == null){
				let today_m = moment();
				to_date = today_m.format('YYYY-MM-DD')
			}
			to_date = to_date + ' 23:59:59';

			filters.push({'field':'"DateOfBooking"', 'type':1, 'value': from_date + ',' + to_date});
		}
		if(selected_dep_from_date != null){
			let dep_from_date = selected_dep_from_date + ' 00:00:00';
			let dep_to_date = selected_dep_to_date;
			if(dep_to_date == null){
				let dep_today_m = moment();
				dep_to_date = dep_today_m.format('YYYY-MM-DD')
			}
			dep_to_date = dep_to_date + ' 23:59:59';
			filters.push({'field':'"DepartureDate"', 'type':1, 'value': dep_from_date + ',' + dep_to_date});
		}

		bookings(start, end, user_token, filters).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
			setSelectedPage(selected_page)
		})
	}

	const reset_filters = () =>{


		setBookingRef(null)
		setSelectedFromDate(null)
		setSelectedToDate(null)
		setSurname(null)
		setSelectedFromDepDate(null)
		setSelectedToDepDate(null)

		bookings(0, 10, user_token, {}).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
			setSelectedPage(1)
		});


	}

	const change_from_date = (v) => {
		setSelectedFromDate(v)
	}	
	
	const change_to_date = (v) => {
		setSelectedToDate(v)
	}

	const change_dep_from_date = (v) => {
		setSelectedFromDepDate(v)
	}	
	
	const change_dep_to_date = (v) => {
		setSelectedToDepDate(v)
	}

	let tbl_view = null;
	if(d_view != null){

		let rows = []
		for(let x of d_view){

			let date_of_booking = moment(x.DateOfBooking, 'YYYY-MM-DD HHmm');
			let str_date_of_booking = date_of_booking.format('DD MMMM YYYY HH:mm');

			let departure_date = moment(x.DepartureDate, 'YYYY-MM-DD HHmm');
			let str_departure_date = departure_date.format('DD MMMM YYYY HH:mm');

			let status = x.Status;

			let conf = 'N';
			let paid = 'N';
			let checked = 'N';

			let status_class = ''
			if(status == 1){
				status_class = 'row_closed'
			}
			else{
				if(x.Confirmed){
					status_class = 'booking_confirmed'
					conf = 'Y'
					paid = 'Y'
					checked = 'Y'
				}
				else if(x.Paid && x.Checked){
					//component fail (last moment fail on reservations)
					status_class = 'booking_part_book'
					paid = 'Y'
					checked = 'Y'

				}
				else if(x.Checked){
					//payment fail (failed to take payment)
					status_class = 'booking_no_payment'
					checked = 'Y'
				}
				else{
					//check fail (failed to check anything so aborted the process but still gets dumped into DB)
					status_class = 'booking_no_check'
				}
			}


			rows.push(<tr className={status_class}>
				<td>
					{x.ID}
				</td>
				<td>
					{str_date_of_booking}
				</td>
				<td>
					{str_departure_date}
				</td>
				<td>
					{x.Customer.FirstName} {x.Customer.Surname}
				</td>
				<td>
					{x.Accommodation.Name}
				</td>
				<td>
					{x.Departure_Point.Name}
				</td>
				<td>
					{x.Duration}
				</td>
				<td>
					A:{x.Adults} C:{x.Children}
				</td>
				<td>
					C:{conf} P:{paid} PC:{checked} 
				</td>
				<td>
					<a href={'/bookings/view?bid=' + x.ID}>View</a>
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						ID
					</td>
					<td>
						Booking Date
					</td>
					<td>
						Departure Date
					</td>
					<td>
						Lead Name
					</td>
					<td>
						Accommodation
					</td>
					<td>
						Departing
					</td>
					<td>
						Duration
					</td>
					<td>
						Pax
					</td>
					<td>
						C P PC
					</td>
					<td>
						
					</td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}

	let pagination = null;
	if(d_view_count > 0){
		let number_of_pages = Math.ceil(d_view_count / 100);

		let pagination_items = []
		for(let i=0; i < number_of_pages; i++){
			let page_no = i + 1;
			let key = 'page_' + page_no;
			let page_class = ''
			if(selected_page == page_no){
				page_class = 'selected'
			}
			pagination_items.push(<li key={key} className={page_class} onClick={() => page(i)}>{page_no}</li>)
		}

		pagination = <ul>
			{pagination_items}
		</ul>
	}

	let str_book_ref = ''
	if(booking_ref != null){
		str_book_ref = booking_ref;
	}

	let str_surname = ''
	if(surname != null){
		str_surname = surname;
	}

	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>
				{/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}


				<div className='filer_sec'>
					<div className='filter_field'>
						<div>
							Booking reference
						</div>
						<div>
							<input id='txtBookingRef' value={str_book_ref} onChange={(e) => setBookingRef(e.target.value)}></input>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Booking date
						</div>
						<div>
							From <YMDPicker id='sel_bk_from' key='sel_bk_from_k' initalValue={selected_from_date} onChange={(value) => change_from_date(value)}></YMDPicker> 
					
							
			 
							To <YMDPicker id='sel_bk_to' key='sel_bk_to_k' initalValue={selected_to_date} onChange={(value) => change_to_date(value)}></YMDPicker>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Departure date
						</div>
						<div>
							From <YMDPicker id='sel_dep_from' key='sel_dep_from_k' initalValue={selected_dep_from_date} onChange={(value) => change_dep_from_date(value)}></YMDPicker> 
					
							
			 
							To <YMDPicker id='sel_dep_to' key='sel_dep_to_k' initalValue={selected_dep_to_date} onChange={(value) => change_dep_to_date(value)}></YMDPicker>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Surname
						</div>
						<div>
							<input id='txtSurname' key='txtSurname' value={str_surname} onChange={(e) => setSurname(e.target.value)}></input>
						</div>
					</div>			
					
					<div className='filter_field'>
						<div>
							<button onClick={() => filter(0, 100)}>Filter</button>
							<button onClick={() => reset_filters()}>Reset</button>
						</div>
					</div>

				</div>

				<div className='ledgend'>
					<div className='booking_confirmed block'>
						Booking completed.
					</div>
					<div className='booking_part_book block'>
						Issue(s) at reservation.
					</div>
					<div className='booking_no_payment block'>
						Payment could not be taken.
					</div>
					<div className='booking_no_check block'>
						Products could not be price checked.
					</div>
				</div>

				<div className='booking_tbl'>
					{tbl_view}
				</div>

				<div className='pagination'>
					{pagination}
				</div>

			</div>
		</section>
	);
};

export default Bookings;
