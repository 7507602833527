import React, {useEffect, useState} from 'react';
import {baskets} from '../../service/basket'
import '../enquiries/style.css';
import moment from 'moment';

const Basket = ({user_token}) => {

    const [d_view, setDataView] = useState(null);
	const [d_view_count, setDataViewCount] = useState(0)
	const [selected_page, setSelectedPage] = useState(1)

	const [basket_ref, setBasketRef] = useState(null)

    useEffect(() =>{

		baskets(0, 10, user_token, {}).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
		});

    }, [])
	
	const page = (start) => {
		//change the view
		let selected_page = start + 1;
		let start_row = 0;
		let end_row = 10;
		if(start > 0){
			start_row = start * 10;
			end_row = start_row + 10;
		}
	
		baskets(start_row, end_row, user_token, {}).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
			setSelectedPage(selected_page)
		})
	}

	const filter = () => {
		let filters = []
		if(basket_ref != null && basket_ref != ''){
			filters.push({'field':'reference', 'type':0, 'value': basket_ref});
		}

		baskets(0, 10, user_token, filters).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
			setSelectedPage(1)
		})
	}

	const reset_filters = () =>{


		// setBookingRef(null)
		// setSelectedFromDate(null)
		// setSelectedToDate(null)
		// setSurname(null)
		// setSelectedFromDepDate(null)
		// setSelectedToDepDate(null)

		setBasketRef(null)

		baskets(0, 10, user_token, {}).then((r) => {
			setDataView(r[0])
			setDataViewCount(r[1])
			setSelectedPage(1)
		});


	}

	let tbl_view = null;
	if(d_view != null){

		let rows = []
		for(let x of d_view){

			let date_of_basket = moment(x.Created, 'DD/MM/YY HHmm');
			let str_bask_date = date_of_basket.format('DD MMMM YYYY HH:MM');

			let check_in = moment(x.Check_in, 'DD/MM/YY');
			let str_check_in = check_in.format('DD MMMM YYYY');

			let status = x.Status;

			let status_class = ''
			if(status == 1){
				status_class = 'row_closed'
			}

			rows.push(<tr className={status_class}>
				<td>
					{x.Customer_Reference}
				</td>
				<td>
					{str_bask_date}
				</td>
				<td>
					{str_check_in}
				</td>
				<td>
					{x.Duration}
				</td>
				<td>
					{x.Accommodation}
				</td>
				<td>
					{x.Destination} {x.Region} {x.Resort}
				</td>
				<td>
					A:{x.Adults} C:{x.Children}
				</td>
				<td>
					{x.Initial_price} 
				</td>
				<td>
					<a href={'/basket/view?bid=' + x.ID}>View</a>
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						Basket Reference
					</td>
					<td>
						Created 
					</td>
					<td>
						Arrival
					</td>
					<td>
						Duration
					</td>
					<td>
						Accommodation
					</td>
					<td>
						Destination
					</td>
					<td>
						Pax
					</td>		
					<td>
						Price
					</td>
					<td>
						
					</td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}

	let pagination = null;
	if(d_view_count > 0){
		let number_of_pages = Math.ceil(d_view_count / 10);

		let pagination_items = []
		for(let i=0; i < number_of_pages; i++){
			let page_no = i + 1;
			let key = 'page_' + page_no;
			let page_class = ''
			if(selected_page == page_no){
				page_class = 'selected'
			}
			pagination_items.push(<li key={key} className={page_class} onClick={() => page(i)}>{page_no}</li>)
		}

		pagination = <ul>
			{pagination_items}
		</ul>
	}

	let str_basket_ref = ''
	if(basket_ref != null){
		str_basket_ref = basket_ref;
	}



	
	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>
				{/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}


				<div className='filer_sec'>
					<div className='filter_field'>
						<div>
							Basket reference
						</div>
						<div>
							<input id='txtBookingRef' value={str_basket_ref} onChange={(e) => setBasketRef(e.target.value)}></input>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							<button onClick={() => filter()}>Filter</button>
							<button onClick={() => reset_filters()}>Reset</button>
						</div>
					</div>

				</div>

				<div className='booking_tbl'>
					{tbl_view}
				</div>

				<div className='pagination'>
					{pagination}
				</div>

			</div>
		</section>
	);
};

export default Basket;
