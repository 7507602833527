import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {basket} from '../../service/basket'
import '../booking_view/style.css';
import moment from 'moment';

const BasketView = (props) => {

    const [basket_d, setBasketData] = useState(null);
	const [processing, setProcessing] = useState(true)

    useEffect(() =>{
		let queryStrings = queryString.parse(props.history.location.search);
		let basket_id = queryStrings.bid;
		basket(basket_id, props.user_token).then((r) =>{
			setBasketData(r)
			setProcessing(false);
		});

    }, [])


	const extra_sec = (e, i) => {

		if(e.QuantityRequired > 0){
			i.push(<tr>
				<td>Code</td>
				<td>{e.Code}</td>
			</tr>)
			i.push(<tr>
				<td>Description</td>
				<td>{e.Description}</td>
			</tr>)
			i.push(<tr>
				<td>Qty required</td>
				<td>{e.QuantityRequired}</td>
			</tr>)
			
			if(e.Provider_Price != null){
				i.push(<tr>
					<td>Provider cost</td>
					<td>{e.Provider_Price}</td>
				</tr>)
			}
			if(e.Margin != null){
				i.push(<tr>
					<td>Margin</td>
					<td>{e.Margin}</td>
				</tr>)		
			}
			if(e.Original_Price != null){
				i.push(<tr>
					<td>Original cost</td>
					<td>{e.Original_Price}</td>
				</tr>)	
			}
			if(e.Discount != null){
				i.push(<tr>
					<td>Discount</td>
					<td>{e.Discount}</td>
				</tr>)	
			}
			if(e.Price != null){

				i.push(<tr>
					<td>Item cost</td>
					<td>{e.Price}</td>
				</tr>)	

				i.push(<tr>
					<td>Total cost</td>
					<td>{e.Price * e.QuantityRequired}</td>
				</tr>)
			}
		}

		return i;
	}
	

	let tbl_view_flights =  null;
	let tbl_view_acc = null;
	let tbl_view_transfer = null;
	let tbl_total_cost = null;
    let tbl_general = null;
	

	let customer_rows = []

	if(basket_d != null){

		let customer_counter = 1;

		// let created = moment(basket_d.Created, 'YYYY-MM-DD HH:mm');
		// created = created.format('DD MMMM YYYY HH:mm');

		let created = moment(basket_d.Created, 'DD/MM/YY HHmm');
		created = created.format('DD MMMM YYYY HH:MM');

		let check_in = moment(basket_d.Holiday.Check_in, 'DD/MM/YY');
		let str_check_in = check_in.format('DD MMMM YYYY');

		let selected_outbound = null;
		let selected_inbound = null;
		let selected_room = null;
		let flight_costings = [];
		let flight_inbound_costings = [];
		let flight_extras = [];
		let flight_extras_inbound = [];
		
		for(let obf of basket_d.Holiday.OutboundFlights){
			if(obf.ID == basket_d.Holiday.OutboundFlight){
				selected_outbound = obf;
				break;
			}
		}

		for(let ibf of basket_d.Holiday.ReturnFlights){
			if(ibf.ID == basket_d.Holiday.ReturnFlight){
				selected_inbound = ibf;
				break;
			}
		}

		for(let rm of basket_d.Holiday.Rooms){
			if(rm.ID == basket_d.Holiday.SelectedRoom){
				selected_room = rm;
				break;
			}
		}

		let room_margin = null;
		if(selected_room.Margin != null){
			room_margin = 	<tr>
						<td>Margin</td>
						<td>{selected_room.Margin}</td>
					</tr>;
		}
		let room_provider_price = null;
		if(selected_room.Provider_Price != null){
			room_provider_price = <tr>
						<td>Provider cost</td>
						<td>{selected_room.Provider_Price}</td>
					</tr>
		}
		let room_orig_price = null;
		if(selected_room.Original_Price != null){
			room_orig_price =  <tr>
						<td>Original cost</td>
						<td>{selected_room.Original_Price}</td>
					</tr>
		}	
		let room_discount = null;
		if(selected_room.Discount != null){
			room_discount =  <tr>
						<td>Discount</td>
						<td>{selected_room.Discount}</td>
					</tr>
		}
		let room_cost = null;
		if(selected_room.Price != null){
			room_cost = <tr>
						<td>Cost</td>
						<td>{selected_room.Price}</td>
					</tr>
		}

		tbl_view_acc = <table>
		<tbody>
			<tr>
				<td className='title_row' colspan='2'>Accommodation</td>
			</tr>
			<tr>
				<td>Name</td>
				<td>{basket_d.Holiday.Accommodation.Name}</td>
			</tr>
			<tr>
				<td>Address</td>
				<td>{basket_d.Holiday.Accommodation.Address}</td>
			</tr>
			<tr>
				<td>Room</td>
				<td>
					{selected_room.Description}<br/>
					{selected_room.Board_Description}<br/>
				</td>
			</tr>
			<tr>
				<td>Provider</td>
				<td>
					{selected_room.Supplier}
				</td>
			</tr>
			{room_provider_price}
			{room_margin}
			{room_orig_price}
			{room_discount}
			{room_cost}
		</tbody>
		</table>

		if(selected_outbound != null && selected_inbound != null){
			let ispackage = selected_outbound.IsPackage;

			let outbound_flight_departing = moment(selected_outbound.Departing, 'YYYY-MM-DD.HH:mm')
			outbound_flight_departing = outbound_flight_departing.format('DD/MM/YY HH:mm');
			let outbound_flight_arrival =  moment(selected_outbound.Arrival, 'YYYY-MM-DD.HH:mm')
			outbound_flight_arrival = outbound_flight_arrival.format('DD/MM/YY HH:mm');

			let inbound_flight_departing = moment(selected_inbound.Departing, 'YYYY-MM-DD.HH:mm')
			inbound_flight_departing = inbound_flight_departing.format('DD/MM/YY HH:mm');
			let inbound_flight_arrival =  moment(selected_inbound.Arrival, 'YYYY-MM-DD.HH:mm')
			inbound_flight_arrival = inbound_flight_arrival.format('DD/MM/YY HH:mm');

			if(ispackage){

				//outbound costs
				if(selected_outbound.Provider_Price != null){
					flight_costings.push(<tr>
						<td>Provider cost</td>
						<td>{selected_outbound.Provider_Price}</td>
					</tr>)
				}
			
				if(selected_outbound.Margin != null){
					flight_costings.push(<tr>
						<td>Flight margin</td>
						<td>{selected_outbound.Margin}</td>
					</tr>)
				}
				if(selected_outbound.Original_Price != null){
					flight_costings.push(<tr>
						<td>Flight original cost</td>
						<td>{selected_outbound.Original_Price}</td>
					</tr>)
				}
				if(selected_outbound.Discount != null){
					flight_costings.push(<tr>
						<td>Flight discount</td>
						<td>{selected_outbound.Discount}</td>
					</tr>)		
				}	
				if(selected_outbound.Price != null){
					flight_costings.push(<tr>
						<td>Flight cost</td>
						<td>{selected_outbound.Price}</td>
					</tr>)
				}
			}else{
				//outbound costs
				//outbound costs
				if(selected_outbound.Provider_Price != null){
					flight_costings.push(<tr>
						<td>Provider cost</td>
						<td>{selected_outbound.Provider_Price}</td>
					</tr>)
				}
			
				if(selected_outbound.Margin != null){
					flight_costings.push(<tr>
						<td>Flight margin</td>
						<td>{selected_outbound.Margin}</td>
					</tr>)
				}
				if(selected_outbound.Original_Price != null){
					flight_costings.push(<tr>
						<td>Flight original cost</td>
						<td>{selected_outbound.Original_Price}</td>
					</tr>)
				}
				if(selected_outbound.Discount != null){
					flight_costings.push(<tr>
						<td>Flight discount</td>
						<td>{selected_outbound.Discount}</td>
					</tr>)		
				}	
				if(selected_outbound.Price != null){
					flight_costings.push(<tr>
						<td>Flight cost</td>
						<td>{selected_outbound.Price}</td>
					</tr>)
				}

				//return costs 
				if(selected_inbound.Provider_Price != null){
					flight_inbound_costings.push(<tr>
						<td>Provider cost</td>
						<td>{selected_inbound.Provider_Price}</td>
					</tr>)
				}
			
				if(selected_inbound.Margin != null){
					flight_inbound_costings.push(<tr>
						<td>Flight margin</td>
						<td>{selected_inbound.Margin}</td>
					</tr>)
				}
				if(selected_inbound.Original_Price != null){
					flight_inbound_costings.push(<tr>
						<td>Flight original cost</td>
						<td>{selected_inbound.Original_Price}</td>
					</tr>)
				}
				if(selected_inbound.Discount != null){
					flight_inbound_costings.push(<tr>
						<td>Flight discount</td>
						<td>{selected_inbound.Discount}</td>
					</tr>)		
				}	
				if(selected_inbound.Price != null){
					flight_inbound_costings.push(<tr>
						<td>Flight cost</td>
						<td>{selected_inbound.Price}</td>
					</tr>)
				}
			}

			if(!ispackage){
				let have_inbound_extras = false;
				for(let e of selected_inbound.Extras){
					if(e.QuantityRequired > 0){
						have_inbound_extras = true;
					}
					extra_sec(e, flight_extras_inbound)
				}
				if(have_inbound_extras){
					flight_extras_inbound.splice(0,0,<tr>
						<td className='title_row' colspan='2'>Inbound extras</td>
					</tr>)
				}
			}

			let have_outbound_extras = false;
			for(let e of selected_outbound.Extras){
				if(e.QuantityRequired > 0){
					have_outbound_extras = true;
				}
				extra_sec(e, flight_extras)
			}
			if(have_outbound_extras){
				flight_extras.splice(0,0,<tr>
					<td className='title_row' colspan='2'>Outbound extras</td>
				</tr>)
			}

            let is_pak = 'No';
            if(selected_outbound.IsPackage){
                is_pak = 'Yes';
            }

	

            tbl_general = <table>
                <tbody>
                    <tr>
						<td>Reference</td>
						<td>{basket_d.Customer_Reference}</td>
					</tr>
                    <tr>
						<td>Created</td>
						<td>{created}</td>
					</tr>
                    <tr>
						<td>Adults</td>
						<td>{basket_d.Adults}</td>
					</tr>
                    <tr>
						<td>Children</td>
						<td>{basket_d.Children}</td>
					</tr>
                </tbody>
            </table>

			tbl_view_flights = <table>
				<tbody>
					<tr>
						<td>Is package</td>
						<td>{is_pak}</td>
					</tr>
					<tr>
						<td className='title_row' colspan='2'>Outbound flight</td>
					</tr>
					<tr>
						<td>Booking reference</td>
						<td></td>
					</tr>
					<tr>
						<td>Route</td>
						<td>{selected_outbound.DepartureAirport.Code.toUpperCase() + '-' + selected_outbound.ArrivalAirport.Code.toUpperCase()}</td>
					</tr>
					<tr>
						<td>Supplier</td>
						<td>{selected_outbound.Airline}</td>
					</tr>
					<tr>
						<td>Flight number</td>
						<td>{selected_outbound.Number}</td>
					</tr>
					<tr>
						<td>Departing</td>
						<td>{outbound_flight_departing}</td>
					</tr>
					<tr>
						<td>Arriving</td>
						<td>{outbound_flight_arrival}</td>
					</tr>
					{flight_costings}
					<tr>
						<td className='title_row' colspan='2'>Return flight</td>
					</tr>
					<tr>
						<td>Booking reference</td>
						<td></td>
					</tr>
					<tr>
						<td>Route</td>
						<td>{selected_inbound.DepartureAirport.Code.toUpperCase() + '-' + selected_inbound.ArrivalAirport.Code.toUpperCase()}</td>
					</tr>
					<tr>
						<td>Supplier</td>
						<td>{selected_inbound.Airline}</td>
					</tr>
					<tr>
						<td>Flight number</td>
						<td>{selected_inbound.Number}</td>
					</tr>
					<tr>
						<td>Departing</td>
						<td>{inbound_flight_departing}</td>
					</tr>
					<tr>
						<td>Arriving</td>
						<td>{inbound_flight_arrival}</td>
					</tr>
					{flight_inbound_costings}
					{flight_extras}
					{flight_extras_inbound}
				</tbody>
			</table>
		}

		if(basket_d.Holiday.Transfer != null){
			let selected_transfer ;
			for(let t of basket_d.Holiday.Transfers){
				if(t.ID == basket_d.Holiday.Transfer){
					selected_transfer = t;
					break;
				}
			}

			let t_margin = null;
			if(selected_transfer.Margin != null){
				t_margin = 	<tr>
							<td>Margin</td>
							<td>{selected_transfer.Margin}</td>
						</tr>;
			}
			let t_provider_price = null;
			if(selected_transfer.Provider_Price != null){
				t_provider_price = <tr>
							<td>Provider cost</td>
							<td>{selected_transfer.Provider_Price}</td>
						</tr>
			}
			let t_orig_price = null;
			if(selected_transfer.Original_Price != null){
				t_orig_price =  <tr>
							<td>Original cost</td>
							<td>{selected_transfer.Original_Price}</td>
						</tr>
			}	
			let t_discount = null;
			if(selected_transfer.Discount != null){
				t_discount =  <tr>
							<td>Discount</td>
							<td>{selected_transfer.Discount}</td>
						</tr>
			}
			let t_cost = null;
			if(selected_transfer.Price != null){
				t_cost = <tr>
							<td>Cost</td>
							<td>{selected_transfer.Price}</td>
						</tr>
			}

			tbl_view_transfer = <table>
			<tbody>
				<tr>
					<td className='title_row' colspan='2'>Transfer</td>
				</tr>
				<tr>
					<td>Type</td>
					<td>{selected_transfer.ProductType}</td>
				</tr>
				<tr>
					<td>Description</td>
					<td>{selected_transfer.Description}</td>
				</tr>
				{t_provider_price}
				{t_margin}
				{t_orig_price}
				{t_discount}
				{t_cost}
			</tbody>
			</table>



		}

		let holiday_margin = null;
		if(basket_d.Holiday.Margin != null){
			holiday_margin = <tr>
							<td>Margin</td>
							<td>{basket_d.Holiday.Margin}</td>
						</tr>;
		}


		tbl_total_cost = <table>
		<tbody>
				<tr>
					{holiday_margin}
				</tr>
				<tr>
					<td>Cost</td>
					<td>{basket_d.Holiday.Price}</td>
				</tr>
			</tbody>
		</table>
	}

	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>

                <div className='flight_tbl tbl_sec'>
					{tbl_general}
				</div>

				<div className='flight_tbl tbl_sec'>
					{tbl_view_flights}
				</div>

				<div className='transfer_tbl tbl_sec'>
					{tbl_view_transfer}
				</div>

				<div className='acc_tbl tbl_sec'>
					{tbl_view_acc}
				</div>

				<div className='cost_tbl tbl_sec'>
					{tbl_total_cost}
				</div>

			</div>
		</section>
	);
};

export default BasketView;
