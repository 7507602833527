import React, {useEffect, useState} from 'react';
import {component_schema} from '../../service/component'

const Component_Test = ({user_token}) => {

    const [orig_schema, setOrigSchema] = useState(null);
    const [schema, setComSchema] = useState(null);
    const [tester, setTester] = useState(null);

    useEffect(() =>{
        //hard coded to pull component id 3's schema
        component_schema(6, user_token).then((r) => {

            let sc = r[0]
            let fields = []
            //output the schema fields
            for(let f of sc.fields){

                let min_amount = f.min;
                let max_amount = f.max;
                let target = f.target;
                let inputs = f.field;

                let input_fields = [];
                for(let i of inputs){
                    let i_type = i.type;
                    let i_name = i.name;
                    let i_label = i.label;
                    let dval = null;
                    if(i_type == 'bool'){
                        dval = false;
                    }
                    input_fields.push({'type': i_type, 'name': i_name, 'label': i_label, 'value': dval})
                }

                fields.push({'target': target, 'min_amount': min_amount, 'max_amount': max_amount, 'qty_selected': 1,'items':[{'index':1, 'inputs': input_fields}]})
            }
            setOrigSchema(fields);
            setComSchema(fields);
		});
    }, [])


    const checkbox_field = (name, lbl, target, index, value=false) => {
        let d = <div style={{display:'flex', width:'100%'}}>
            <div style={{width:'120px'}}>{lbl}</div>
            <div style={{marginLeft: '10px'}}><input name={name} checked={value} type="checkbox" key={index + '-' + target + '-' + name} onClick={(e) => set_value(target, name, e.target.checked, index)}></input></div>
        </div>
        return d;
    }    
    
    const text_field = (name, lbl, target, index, value) => {
        let d = <div style={{display:'flex', width:'100%'}}>
            <div style={{width:'120px'}}>{lbl}</div>
            <div style={{marginLeft: '10px'}}><input name={name} key={index + '-' + target + '-' + name} value={value} onChange={(e) => set_value(target, name, e.target.value, index)}></input></div>
        </div>
        return d;
    }

    const set_value = (target, name, value, index) => {
        // let fields = schema;
        let fields = JSON.parse(JSON.stringify(schema))
        for(let x of fields){
            if(x.target == target){
                for(let i of x.items){
                    if(i.index == index){
                        for(let n of i.inputs){
                            if(n.name == name){
                                n.value = value;
                            }
                        }
                    }
                }
            }
        }
        setComSchema(fields)
    }

    const add_item = (target) => {
        let copy = null;
        for(let x of orig_schema){
            if(x.target == target){
                copy = JSON.parse(JSON.stringify(x.items[0]))
            }
        }
        if(copy != null){
            let fields = JSON.parse(JSON.stringify(schema))
            for(let x of fields){
                if(x.target == target){
    
                    let qty_selected = x.items.length;
                    let index = qty_selected + 1;
                    copy.index = index;
                    x.qty_selected = index;
                    x.items.push(copy)
                }
            }
            setComSchema(fields)
        }
    }

    const remove_item = (target, index) => {
        let fields = JSON.parse(JSON.stringify(schema))
        let index_to_remove = index - 1;
            for(let x of fields){
            if(x.target == target){
                x.items.splice(index_to_remove, 1);
                x.qty_selected = x.items.length;

                let ix = 1;
                for(let i of x.items){
                    i.index = ix;
                    ix++;
                }

            }
        }
        setComSchema(fields)
    }

    const save_changes = () => {

        let o = {}
        for(let x of schema){
            if(x.min_amount == 1 && x.max_amount == 1){
                if(x.items.length == 1){
                    if(x.items[0].inputs.length == 1){
                        o[x.target] = x.items[0].inputs[0].value;
                    }
                    else{
                        let item_d = {}
                        for(let s of x.items[0].inputs){
                            item_d[s.name] = s.value;
                        }
                        o[x.target] =  item_d
                    }
                }
            }
            else{
                let target = x.target;
                let target_items = []
                for(let i of x.items){
                    if(i.inputs.length == 1){
                        let item_d = {}
                        item_d[i.inputs[0].name] = i.inputs[0].value;
                        target_items.push(item_d)
                    }
                    else{
                        let item_d = {}
                        for(let s of i.inputs){
                            item_d[s.name] = s.value;
                        }
                        target_items.push(item_d)
                    }
                }

                o[target] = target_items

            }
        }
        let str_config = JSON.stringify(o)

        //update (send to the engine)
        let test = '123';
    }


    if(schema != null){

        let fsecs = []
        let sc = schema;
        //output the schema fields
        for(let f of sc){

            let min_amount = f.min_amount;
            let max_amount = f.max_amount;
            let qty_selected = f.qty_selected;
            let target = f.target;
            let inputs = f.inputs;

            let input_items = []
      
            for(let i of f.items){
                let input_fields = [];
                let item_index = i.index; 

                let remove_button = null;
                if(max_amount > 1 && item_index >= min_amount){
                    remove_button = <div><button onClick={() => remove_item(target, item_index)}>Remove</button></div>
                }

                for(let n of i.inputs){
                    let i_type = n.type;
                    let i_name = n.name;
                    let i_label = n.label;
    
                


                    if(i_type == 'bool'){
                        input_fields.push(checkbox_field(i_name, i_label, target, item_index, n.value))
                    }
                    else if(i_type == 'string'){
                        input_fields.push(text_field(i_name, i_label, target, item_index, n.value))
                    }
                }

                let d = <div style={{width: '100%', padding: '5px 5px 5px 5px', display:'flex', flexFlow:'wrap'}}>
                        {input_fields}     
                        {remove_button}
                </div>

                input_items.push(d);
            }
  
            let add_btn = null;
            if((max_amount > 1 && qty_selected < max_amount) || max_amount == -1){
                //add a button so the user can add more of these fields if they want
                add_btn = <div><button onClick={() => add_item(target)}>Add</button></div>
            }

            let d = <div style={{width: '100%', borderBottom: '1px dotted black', padding: '5px 5px 5px 5px', display:'flex', flexFlow:'wrap'}}>
                {input_items}
                {add_btn}
            </div>

            fsecs.push(d);

        }

        return(
            <div style={{margin: '20px 20px 20px 20px'}}>
                {fsecs}
                <div style={{margin: '10px 0px 0px 5px'}}>
                    <button onClick={() => save_changes()} style={{color:'white', backgroundColor:'green'}}>Save</button>
                </div>
            </div>
        )


    }
    else{
        return null;
    }




};

export default Component_Test;