import React, {useEffect, useState} from 'react';

const LogIn = ({
    log_in_func,
}) => {

    const [user_name, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() =>{
    }, [])

    const log_in = async() => {
        let value = log_in_func(user_name, password)

    }


	return (
		<section>
			<div style={{display: 'flex', justifyContent: 'center'}}>
				
                <div style={{paddingTop:'10px'}}>
                    Username<br></br>
                    <input onChange={(e) => setUsername(e.target.value)}></input>
                </div>
                <div style={{paddingTop:'10px', paddingLeft:'10px'}}>
                    Password<br></br>
                    <input onChange={(e) => setPassword(e.target.value)} type='password'></input>
                </div>
			</div>

            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                <div>
                    <button onClick={(e) => log_in()}>Sign in</button>
                </div>
			</div>
		</section>
	);
};

export default LogIn;
