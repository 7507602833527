import React, { useState, useEffect,  } from "react";
import moment from "moment";


const YMDPicker = ({
  onChange,
  validate = null,
  initalValue = null,
}) => {

  let m = moment()

  const [selected_day, update_selected_day] = useState(null, () => update_selection);
  const [selected_month, update_selected_month] = useState(null,() => update_selection);
  const [selected_year, update_selected_year] = useState(null, () => update_selection);


  let days = [];
  let _months = [1,2,3,4,5,6,7,8,9,10,11,12]
  let months = [];
  let years = [];

  useEffect(() => {
    if(initalValue != null){
        let ini_dob = moment(initalValue,"YYYY-MM-DD");
        update_selected_day(ini_dob.date());
        update_selected_year(ini_dob.year());
        update_selected_month(ini_dob.month() + 1);
      }
  }, []);

  useEffect(() => {
    if(initalValue == null){
        //reset this control
        update_selected_day(null);
        update_selected_year(null);
        update_selected_month(null);
    }
  }, [initalValue]);

  useEffect(() =>{
    update_selection();
  },[selected_day, selected_month, selected_year]);


//   let ini_dob = moment()
//   if(initalValue != null){
//     ini_dob = moment(initalValue,"YYYY-MM-DD");
//     // update_selected_day(ini_dob.day());
//     // update_selected_month(ini_dob.month() + 1);
//     // update_selected_year(ini_dob.year());
//   }

  let sel_dob = moment(selected_year + "/" + selected_month + "/" + 1,"YYYY-MM-DD");

  days.push(<option>-</option>);

  let max_days = sel_dob.daysInMonth();

  if(selected_day <= max_days){
    sel_dob = moment(selected_year + "/" + selected_month + "/" + selected_day,"YYYY-MM-DD");
  }
  else{
    sel_dob = moment(selected_year + "/" + selected_month + "/" + max_days,"YYYY-MM-DD");
  }

  if(selected_month == null && selected_year == null){
      max_days = 31;
  }
  for(let i =1; i <= max_days; i++){
    if(i == sel_dob.date()){
        days.push(<option selected='true'>{i}</option>);
    }
    else{
        days.push(<option>{i}</option>);
    }
  }

  months.push(<option>-</option>)
  for(let i =1; i <= _months.length; i++){
    let m = moment().set('month', (i -1))
    if(i == (sel_dob.month() + 1)){
        months.push(<option selected='true' value={i}>{m.format("MMM")}</option>);
    }
    else{
        months.push(<option value={i}>{m.format("MMM")}</option>);
    }
  }


    let todays_year = moment().year();

    years.push(<option>-</option>)

    for(let i = todays_year - 5; i <= todays_year; i++){
        if(i == sel_dob.year()){
            years.push(<option selected='true'>{i}</option>);
        }
        else{
            years.push(<option>{i}</option>);
        }
    }
  

  const select_day = (value) => {
    update_selected_day(value);
    if(selected_month == null){
        update_selected_month(1);
    }
    if(selected_year == null){
        update_selected_year(todays_year);
    }
  }
  const select_month = (value) => {
    update_selected_month(value);
    if(selected_day == null){
        update_selected_day(1);
    }    
    if(selected_year == null){
        update_selected_year(todays_year);
    }
  }
  const select_year = (value) => {
    update_selected_year(value);
    if(selected_month == null){
        update_selected_month(1);
    }
    if(selected_day == null){
        update_selected_day(1);
    }
  }

  const update_selection = () =>{

      if(selected_year == '-' || selected_month == '-' || selected_day == '-') {
          onChange(null);
          return;
      }


    if(selected_year != null && selected_month != null & selected_day != null){
        onChange(selected_year + "-" + selected_month + "-" + selected_day);
    }
    else{
        onChange(null)
    }

   }

 

  return(
    <div className="field">
    <div className="control">
      <div
        className={"select"}
      >
        <select
          className=""
          onChange={(e) => select_day(e.target.value)}
        >
          {days}
        </select>

        <select
          className=""
          onChange={(e) => select_month(e.target.value)}
        >
          {months}
        </select>

        <select
          className=""
          onChange={(e) => select_year(e.target.value)}
        >
          {years}
        </select>
      </div>
    </div>
  </div>
  );

}

export default YMDPicker;
